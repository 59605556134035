<template src="./sidebarZoneComponent.html"></template>

<script>
    import Vue from 'vue'
    import sidebarSensorComponent from '../sidebarSensorComponent/sidebarSensorComponent'

    export default {
        name: 'sidebar-zone-component',
        props: [
            'group'
        ],
        components: {
            sidebarSensorComponent
        },
        data: function () {
            return {
                sidebarSensorComponentTemplate: function (zone) {
                    return () => {
                        return {
                            template: {
                                extends: sidebarSensorComponent,
                                propsData: {zone: zone}
                            }
                        }
                    }
                },
                showZone: true
            }
        },
        methods: {
            toggleShowZone(zone, e) {
                Vue.set(zone, 'showZone', !zone.showZone);
                // eventBus.$emit('updateZonePreferences', this.group.GroupID, zone.ZoneName);

                e.stopPropagation();
                e.preventDefault();

                this.$forceUpdate();

                setTimeout(() => {
                    for (let i = 0; i < this.$refs.acc.length; i++) {
                        if (this.$refs.acc[i].ZoneName === zone.ZoneName) {
                            if (zone.showZone) {
                                this.$refs.acc[i].expandItem(true, 0);
                            } else {
                                this.$refs.acc[i].expandItem(false, 0);
                            }
                        }
                    }
                }, 100);

                this.updatePreferences(zone);
            },
            updatePreferences(zone) {
                if(app.signedInUser.Preferences) {
                    let groupPreference = app.signedInUser.Preferences.SensorMapViewDisplay.filter(p => p.Group.ID === this.group.GroupID)[0];
                    if(groupPreference) {
                        let zonePreference = groupPreference.Zones.filter(z => z.Name === zone.ZoneName)[0];
                        if(zonePreference) {
                            zonePreference.DisplayAsDefault = zone.showZone;
                        } else {
                            groupPreference.Zones.push({
                                Name: zone.ZoneName,
                                DisplayAsDefault: zone.showZone
                            });
                        }
                    } else {
                        let preference = {
                            Group: {
                                ID: this.group.GroupID,
                                Name: this.group.GroupName,
                                DisplayAsDefault: true
                            },
                            Zones: [
                                {
                                    Name: zone.ZoneName,
                                    DisplayAsDefault: zone.showZone
                                }
                            ]
                        };

                        app.signedInUser.Preferences.SensorMapViewDisplay.push(preference);
                    }
                } else {
                    app.signedInUser.Preferences = {
                        SensorMapViewDisplay: [
                            {
                                Group: {
                                    ID: this.group.GroupID,
                                    Name: this.group.GroupName,
                                    DisplayAsDefault: true
                                },
                                Zones: [
                                    {
                                        Name: zone.ZoneName,
                                        DisplayAsDefault: zone.showZone
                                    }
                                ]
                            }
                        ]
                    };
                }

                eventBus.$emit('updateMapByZonePreferences', this.group.GroupID, zone);

                DM.http({
                    method: 'POST',
                    url: '/User/UpdatePreferences',
                    data: app.signedInUser.Preferences
                }, true);
            }
        },
        created() {

        },
        mounted() {
            if(this.$refs.acc) {
                for(let i = 0; i < this.$refs.acc.length; i++) {
                    this.$refs.acc[i].zoneName = this.group.Zones[i].ZoneName;

                    if(this.group.Zones[i].showZone) {
                        this.$refs.acc[i].expandItem(true,0);
                    }
                }
            }
        }
    }
</script>
