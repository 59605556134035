var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-height-100"},[_c('dm-table',{ref:"grdGroups",attrs:{"table":{
            grdTableItems: _vm.grdGroupsItems,
            grdTableRowOnClick: _vm.grdGroupsRowOnClick,
            grdTableActionOnClick: _vm.grdGroupsActionOnClick,
            grdTableSortBy: _vm.grdGroupsSortBy,
            grdTableFields: _vm.grdGroupsFields,
            class: _vm.grdGroupsClass
        },"items":_vm.grdGroupsItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }