<template src="./sidebarSensorComponent.html"></template>

<script>
    import Vue from 'vue'
    import {
        ChartPlugin,
        CandleSeries,
        Category,
        Tooltip,
        DateTime,
        Zoom,
        Logarithmic,
        Crosshair,
        LineSeries,
        AccumulationDistributionIndicator,
        StripLine,
        BollingerBands,
        RangeAreaSeries
    } from "@syncfusion/ej2-vue-charts";

    export default {
        name: 'sidebar-sensor-component',
        props: [
            'zone'
        ],
        provide: {
            chart: [CandleSeries, Category, LineSeries, Tooltip, DateTime, StripLine, Zoom, Logarithmic, Crosshair, AccumulationDistributionIndicator, BollingerBands, RangeAreaSeries]
        },
        data: function () {
            return {
                crosshair: {enable: true, lineType: 'Vertical'},
                tooltip: {
                    enable: true,
                    shared: true,
                    header: '<b>${point.x}</b>',
                    format: '<b>${point.y}</b>',
                    // template: function() {
                    //     return {
                    //         template: Vue.component('contentTemplate', {
                    //             template: `<div>
                    //
                    //                        </div> `,
                    //             data() {
                    //                 return {
                    //                     data: {}
                    //                 };
                    //             }
                    //         })
                    //     };
                    // }
                },
                signedInUserIsSuperAdmin: false
            }
        },
        methods: {
            openSensorModal(sensor) {
                eventBus.$emit('panMapToLocation', sensor);
                app.openViewLocationModal(sensor.ID, sensor.TimeZoneOffset, 'location_tile', this);
            },
            openConnectionIssueModal() {
                DM.alertShow('We are experiencing difficulty connecting to this AIMS device. This difficulty is usually intermittent. If it persists, please contact Frost Technologies.');
            }
        },
        computed: {
            primaryXAxis() {
                return sensorReadings => {
                    let stripLine = {};
                    for(let i = 0; i < sensorReadings.length; i++) {
                        if(sensorReadings[i].IsForecast) {
                            stripLine = {start: sensorReadings[i].chartDate, end: sensorReadings[sensorReadings.length - 1].chartDate, color: '#000', visible: true, opacity: '0.08'}
                            break;
                        }
                    }

                    return {
                        visible: true,
                        labelStyle: {color: 'transparent'},
                        valueType: 'DateTime',
                        labelFormat: 'MMM d - h:mm a',
                        intervalType: 'Days',
                        crosshairTooltip: { enable: true },
                        // color: '#5fb1f6',
                        stripLines: [
                            stripLine
                        ],
                        labelPadding: 0,
                        majorGridLines: {color: 'transparent'},
                        majorTickLines: {color: 'transparent'}
                    }
                }
            },
            primaryYAxis() {
                return sensorReadings => {
                    let surfaceTemps = sensorReadings.map(r => r.SurfaceTemp);
                    // console.log(surfaceTemps);
                    let max = Math.max(...surfaceTemps) + 5;
                    let min = Math.min(...surfaceTemps) - 5;
                    // console.log(max, min);
                    return {
                        visible: true,
                        // labelStyle: {color: 'transparent'},
                        majorGridLines: {color: 'transparent'},
                        majorTickLines: {color: 'transparent'},
                        maximum: max,
                        minimum: min,
                        labelFormat: '{value}°',
                    };
                }
            },
        },
        created() {
            this.signedInUserIsSuperAdmin = app.signedInUser.IsSuperAdmin;
        }
    }
</script>
