<template src="./reportsPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'reports-page',
        data: function () {
            return {
                grdReportsFields: [],
                grdReportsItems: [],
                grdReportsSortBy: {
                    columns: [
                        {field: 'GroupName', direction: 'Ascending'},
                    ]
                },
                grdReportsClass: 'dm-bg-color-black',
                searchReportType: '',
                reportTypeOptions: [],
                searchCounty: '',
                countyOptions: [],
                searchOrganization: '',
                organizationOptions: [],
                searchText: '',
                grdReportsActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                selectedRow: null,
                group: null,
                groupOptions: [],
            }
        },
        methods: {
            getReports() {
                DM.http({
                    method: 'GET',
                    url: '/Report/GetAll',
                    params: {
                        groupID: this.group,
                        search: this.searchText
                    }
                }).then(response => {
                    this.reports = response;
                    this.grdReportsItems = response;
                });
            },
            grdReportsRowOnClick(args) {
                this.selectedRow = args;
            },
            addReportOnClick() {
                eventBus.$emit('showAppModal', this, 'reportsEditModal', {
                    mode: 'new',
                    report: {},
                    callback: this.getReports
                });
            },
            grdReportsActionOnClick(args) {
                if(args.item.text === 'Edit') {
                    this.openEditModal(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'View People') {
					mixpanel.track('view_report_people_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'report_id': this.selectedRow.rowData.ID })
				
                    this.openPeopleModal(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'View Locations') {
					mixpanel.track('view_report_locations_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'report_id': this.selectedRow.rowData.ID })
				
                    this.openLocationsModal(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'Toggle Disabled') {
                    this.disableReport(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'Delete') {
                    DM.confirmShow('Are you sure you want to delete this report?', '', () => {
                        this.deleteReport(this.selectedRow.rowData.ID);
                    }, null, null, 'No');
                }
            },
            openEditModal(reportID) {
                DM.http({
                    method: 'GET',
                    url: '/Report/GetByID',
                    params: {
                        id: reportID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'reportsEditModal', {
                            mode: 'edit',
                            report: response,
                            callback: this.getReports
                        });
                    });
            },
            openPeopleModal(reportID) {
                DM.http({
                    method: 'GET',
                    url: '/Report/GetByID',
                    params: {
                        id: reportID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'gridListViewModal', {
                            mode: 'view',
                            title: 'View People',
                            items: response.Recipients,
                            fields: [{
                                key: 'Name',
                                label: 'Name',
                            }]
                        });
                    });
            },
            openLocationsModal(reportID) {
                DM.http({
                    method: 'GET',
                    url: '/Report/GetByID',
                    params: {
                        id: reportID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'gridListViewModal', {
                            mode: 'view',
                            title: 'View Locations',
                            items: response.Locations,
                            fields: [{
                                key: 'Name',
                                label: 'Name',
                            }]
                        });
                    });
            },
            disableReport(reportID) {
				mixpanel.track('toggle_report_disable_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'report_id': this.selectedRow.rowData.ID })
			
                DM.http({
                    method: 'POST',
                    url: '/Report/ToggleDisabled',
                    data: {
                        id: reportID
                    }
                })
                    .then(response => {
                        this.getReports();
                    });
            },
            deleteReport(reportID) {
				mixpanel.track('delete_report_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'report_id': this.selectedRow.rowData.ID })
			
                DM.http({
                    method: 'POST',
                    url: '/Report/Delete',
                    params: {
                        id: reportID,
                    }
                }).then(response => {
                    this.getReports();
                });
            },
            searchReportsOnClick() {
                this.getReports();
            },
            getGroupOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Group/GetAll',
                }).then(response => {
                    this.groupOptions = response.map(g => {
                        return {
                            text: g.Name,
                            value: g.ID
                        }
                    });
                });
            },
        },
        created() {
            this.getReports();
            this.getGroupOptions();

            this.signedInUserIsAdmin = app.signedInUser.IsSuperAdmin || app.signedInUser.Groups.filter(g => g.Permission === 'Admin').length > 0;

            if(this.signedInUserIsAdmin) {
                this.grdReportsActions = [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'View Locations'
                    },
                    {
                        text: 'View People'
                    },
                    {
                        text: 'Toggle Disabled'
                    },
                    {
                        text: 'Delete'
                    }
                ];
            } else {
                this.grdReportsActions = [
                    {
                        text: 'View Locations'
                    },
                    {
                        text: 'View People'
                    }
                ];
            }

            let self = this;

            this.grdReportsFields = [
                {
                    type: 'template',
                    width: '51px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdReportsActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdReportsActions: self.grdReportsActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdReportsActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: 'GroupName',
                    label: 'Group'
                },
                {
                    key: 'ReportType',
                    label: 'Type'
                },
                {
                    key: 'LocationsSummary',
                    label: 'Locations'
                },
                {
                    key: 'PeopleSummary',
                    label: 'People'
                },
                {
                    key: 'ReportFrequency',
                    label: 'Frequency'
                },
                {
                    key: 'IsDisabled',
                    label: 'Disabled',
                    formatter(column, data) {
                        return data.IsDisabled ? 'Y' : 'N';
                    },
                    width: '90px',
                    classes: {class: ['dm-text-center']}
                }
            ];
        },
        mounted() {

        }
    }
</script>
