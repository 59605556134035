<template src="./alertsPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'alerts-page',
        data: function () {
            return {
                grdAlertsFields: [],
                grdAlertsItems: [],
                grdAlertsSortBy: {
                    columns: [
                        {field: 'GroupName', direction: 'Ascending'},
                    ]
                },
                grdAlertsClass: 'dm-bg-color-black',
                signedInUserIsSuperAdmin: false,
                searchAlertType: '',
                searchCounty: '',
                countyOptions: [],
                searchOrganization: '',
                organizationOptions: [],
                group: null,
                groupOptions: [],
                searchText: '',
                grdAlertsActions: [],
                selectedRow: null,
                signedInUserIsAdmin: false
            }
        },
        methods: {
            getAlerts() {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetAll',
                    params: {
                        GroupID: this.group,
                        search: this.searchText ? this.searchText : undefined
                    }
                }).then(response => {
                    this.alerts = response;
                    this.grdAlertsItems = response;
                });
            },
            grdAlertsRowOnClick(args) {
                this.selectedRow = args;
            },
            addAlertOnClick() {
                eventBus.$emit('showAppModal', this, 'alertsEditModal', {
                    mode: "new",
                    alert: {},
                    callback: this.getAlerts
                });
            },
            grdAlertsActionOnClick(args) {
                if(args.item.text === 'Edit') {
                    this.openEditModal(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'View People') {
					mixpanel.track('view_alert_people_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'alert_id': this.selectedRow.rowData.ID })
				
                    this.openPeopleModal(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'View Locations') {
					mixpanel.track('view_alert_locations_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'alert_id': this.selectedRow.rowData.ID })
				
                    this.openLocationsModal(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'Toggle Disabled') {
                    this.disableAlert(this.selectedRow.rowData.ID);
                }

                if(args.item.text === 'Delete') {
                    DM.confirmShow('Are you sure you want to delete this alert?', '', () => {
                        this.deleteAlert(this.selectedRow.rowData.ID);
                    }, null, null, 'No');
                }
            },
            openEditModal(alertID) {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetByID',
                    params: {
                        id: alertID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'alertsEditModal', {
                            mode: 'edit',
                            alert: response,
                            callback: this.getAlerts
                        });
                    });
            },
            openPeopleModal(alertID) {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetByID',
                    params: {
                        id: alertID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'gridListViewModal', {
                            mode: 'view',
                            title: 'View People',
                            items: response.Recipients,
                            fields: [{
                                key: 'Name',
                                label: 'Name',
                            },
                            {
                                key: 'PhoneNumber',
                                label: 'Phone',
                                formatter: (column, data) => {
                                    column.rowData = data;
                                    return data.Phone ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                                },
                                width: '70px'
                            },
                            {
                                key: 'Email',
                                label: 'Email',
                                formatter: (column, data) => {
                                    column.rowData = data;
                                    return data.Email ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                                },
                                width: '70px'
                            }]
                        });
                    });
            },
            openLocationsModal(alertID) {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetByID',
                    params: {
                        id: alertID
                    }
                })
                    .then(response => {
                        eventBus.$emit('showAppModal', this, 'gridListViewModal', {
                            mode: 'view',
                            title: 'View Locations',
                            items: response.Locations,
                            fields: [{
                                key: 'Name',
                                label: 'Name',
                            }]
                        });
                    });
            },
            disableAlert(alertID) {
				mixpanel.track('toggle_alert_disable_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'alert_id': this.selectedRow.rowData.ID })
			
                DM.http({
                    method: 'POST',
                    url: '/Alert/ToggleDisabled',
                    data: {
                        id: alertID
                    }
                })
                    .then(response => {
                        this.getAlerts();
                    });
            },
            deleteAlert(alertID) {
				mixpanel.track('delete_alert_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'alert_id': this.selectedRow.rowData.ID })
			
                DM.http({
                    method: 'POST',
                    url: '/Alert/Delete',
                    params: {
                        id: alertID
                    }
                })
                    .then(response => {
                        this.getAlerts();
                    });
            },
            searchAlertsOnClick() {
                this.getAlerts();
            },
            getGroupOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Group/GetAll',
                }).then(response => {
                    this.groupOptions = response.map(g => {
                        return {
                            text: g.Name,
                            value: g.ID
                        }
                    });
                });
            },
        },
        created() {
            this.getGroupOptions();
            this.getAlerts();

            this.signedInUserIsAdmin = app.signedInUser.IsSuperAdmin || app.signedInUser.Groups.filter(g => g.Permission === 'Admin').length > 0;

            if(this.signedInUserIsAdmin) {
                this.grdAlertsActions = [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'View Locations'
                    },
                    {
                        text: 'View People'
                    },
                    {
                        text: 'Toggle Disabled'
                    },
                    {
                        text: 'Delete'
                    }
                ];
            } else {
                this.grdAlertsActions = [
                    {
                        text: 'View Locations'
                    },
                    {
                        text: 'View People'
                    }
                ];
            }

            let self = this;

            this.grdAlertsFields = [
                {
                    type: 'template',
                    width: '51px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdAlertsActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdAlertsActions: self.grdAlertsActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdAlertsActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: 'ConditionsSummary',
                    label: 'Conditions',
                    allowSorting: true
                },
                {
                    key: 'GroupName',
                    label: 'Group',
                    allowSorting: true
                },
                {
                    key: 'LocationsSummary',
                    label: 'Locations',
                    allowSorting: false
                },
                {
                    key: 'PeopleSummary',
                    label: 'People'
                },
                {
                    key: 'AlertRange',
                    label: 'Alert Range'
                },
                {
                    key: 'IsDisabled',
                    label: 'Disabled',
                    formatter(column, data) {
                        return data.IsDisabled ? 'Y' : 'N';
                    },
                    width: '90px',
                    classes: {class: ['dm-text-center']}
                },
            ];
        },
        mounted() {

        }
    }
</script>
