<template src="./filesAddModal.html"></template>

<script>
    import Vue from 'vue'
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'files-add-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'filesAddModal' + DM.getRandomGuid(),
                lblTitle: 'Add Files',
                dropElement: '#droparea',
                path:  {
                    saveUrl: window.apiRoot + '/Location/CreateAttachments',
                },
                success: false
            }
        },
        methods: {
            uploadSuccess() {
                this.success = true;
            },
            uploadBefore(event, file, name) {
                event.customFormData = [{'LocationID': this.modalData.locationID}];
                event.currentRequest.setRequestHeader('Authorization', 'Bearer ' + DM.authToken);

                event.currentRequest.onreadystatechange = function(e) {
                    if(event.currentRequest.readyState === XMLHttpRequest.DONE) {
                        var status = event.currentRequest.status;
                        if (status === 0 || (status >= 200 && status < 400)) {
                            // console.log('success');
                        } else {
                            console.log(e);
                            DM.alertShow(JSON.parse(e.currentTarget.response).Message, 'Error');
                        }
                    }
                }
            }
        },
        created() {

        },
        mounted() {
            this.$refs[this.modalName].$on("close", () => {
                if(this.success) {
                    this.modalData.callback();
                }
            });
        }
    }
</script>
