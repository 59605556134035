<template src="./gridListViewModal.html"></template>

<script>
    import Vue from 'vue'
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'grid-list-view-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'gridListViewModal' + DM.getRandomGuid(),
                lblTitle: '',
                grdFields: [],
                grdItems: [],
                grdSortBy: {
                    columns: [
                        {field: 'Name', direction: 'Ascending'},
                    ]
                },
                grdClass: ''
            }
        },
        methods: {
            grdRowOnClick(data) {

            },
            grdActionOnClick(data) {

            },
        },
        created() {
            this.lblTitle = this.modalData.title;
            this.grdFields = this.modalData.fields;
            this.grdItems = this.modalData.items;
        },
        mounted() {

        }
    }
</script>
