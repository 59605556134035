<template src="./signInPage.html"></template>

<script>
    export default {
        name: 'sign-in-page',
        data: function () {
            return {
                emailAddress: '',
                password: '',
                showForgotPassword: false
            }
        },
        methods: {
            signIn() {
                DM.http({
                    method: "POST",
                    url: "/user/login",
                    data: {
                        email: this.emailAddress,
                        password: this.password
                    }
                }).then(response => {
                    DM.localStorageSet(app.localStorageAuthTokenName, response.AuthToken);
                    DM.localStorageSet(app.localStorageUserIDName, response.UserID);
                    DM.authToken = response.AuthToken;

					mixpanel.identify(response.UserID);
					mixpanel.people.set({ '$distinct_id': response.UserID, '$name': response.Name, '$email': response.Email, 'isSuperAdmin': response.IsSuperAdmin, 'jobTitle': response.JobTitle });
					mixpanel.track('login', {'distinct_id': response.UserID, 'user_id': response.UserID, 'isSuperAdmin': response.IsSuperAdmin, })

                    if(response.IsTempPassword) {
                        eventBus.$emit('showAppModal', this, 'usersPasswordEditModal', {
                            callback: () => {
                                eventBus.$emit('setSignedInUser', response);
                            }});
                    } else {
                        eventBus.$emit('setSignedInUser', response);
                    }
                });
            },
            sendNewPassword() {
                DM.http({
                    method: "GET",
                    url: "/User/ForgotPassword",
                    params: {
                        email: this.emailAddress,
                    }
                }).then(response => {
                    this.showForgotPassword = false;
                });
            }
        },
        created() {

        }
    }
</script>
