import {createSpinner, showSpinner, hideSpinner, setSpinner} from '@syncfusion/ej2-popups';

export default {
    data() {
        return {
            modalsOpened: [],
        }
    },
    methods: {
        loadPage(componentName) {
            this.tmpMainTemplateName = componentName;
        },

        loadModal(scope, modalComponentName, initData) {
            if (initData === undefined || initData === null) {
                initData = {};
            }

            let modalObject = {
                modalRefGuid: DM.getRandomGuid(),
                modalComponent: modalComponentName,
                modalData: initData
            };

            this.modalsOpened.push(modalObject);
        },
    },
    watch: {},
    created() {

    },
    mounted() {
        eventBus.$on('showAppModal', (scope, modalComponentName, initData) => {
            this.loadModal(scope, modalComponentName, initData);
        });

        eventBus.$on('closeAppModal', (eventData) => {
            // console.log(eventData);
            // console.log(this.modalsOpened);
            // console.log(this);
            // console.log(this.$refs[eventData.modalRefGuid][0]);
            // this.$refs[eventData.modalRefGuid][0].destroy();
            // delete this.$refs[eventData.modalRefGuid];
            this.modalsOpened = this.modalsOpened.filter(m => m.modalRefGuid !== eventData.modalRefGuid);
            // console.log(this.modalsOpened);
        });
    }
}