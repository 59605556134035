var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-height-100"},[_c('dm-table',{ref:"grdUsers",attrs:{"table":{
            grdTableItems: _vm.grdUsersItems,
            grdTableRowOnClick: _vm.grdUsersRowOnClick,
            grdTableActionOnClick: _vm.grdUsersActionOnClick,
            grdTableSortBy: _vm.grdUsersSortBy,
            grdTableFields: _vm.grdUsersFields,
            class: _vm.grdUsersClass
        },"items":_vm.grdUsersItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }