<template src="./sensorsHistoryModal.html"></template>

<script>
    import Vue from 'vue'
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import {
        ChartPlugin,
        CandleSeries,
        Category,
        Tooltip,
        DateTime,
        Zoom,
        Logarithmic,
        Crosshair,
        LineSeries,
        AccumulationDistributionIndicator,
        StripLine,
        ColumnSeries,
        ScatterSeries
    } from "@syncfusion/ej2-vue-charts";

    export default {
        name: 'sensors-history-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        provide: {
            chart: [CandleSeries, Category, LineSeries, Tooltip, DateTime, StripLine, Zoom, Logarithmic, Crosshair, AccumulationDistributionIndicator, ColumnSeries, ScatterSeries]
        },
        data: function () {
            return {
                modalName: 'sensorsHistoryModal' + DM.getRandomGuid(),
                lblTitle: '',
                componentKey: 0,
                grdSensorReadingsFields: [],
                grdSensorReadingsItems: [],
                grdSensorReadingsSortBy: {
                    columns: [
                        {field: 'ReadingDateTimeUTC', direction: 'Descending'},
                    ]
                },
                grdSensorReadingsClass: 'dm-bg-color-black',
                grdSensorReadingsActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                selectedRow: null,
                sensor: {},
                primaryYAxisTemp: {
                    visible: true,
                    opposedPosition: true,
                    labelFormat: '{value}',
                    // labelStyle: {color: 'transparent'},
                },
                primaryYAxisHumidity: {
                    visible: true,
                    labelFormat: '{value}',
                    // labelStyle: {color: 'transparent'},
                },
                primaryYAxisDiagnosticCode: {
                    visible: true,
                    labelFormat: '{value}',
                    // labelStyle: {color: 'transparent'},
                },
                marker: {
                    height: 6,
                    width: 6,
                    fill: '#319cf4',
                    dataLabel: {
                        visible: false
                    }
                },
                indicators: [{
                    type: 'AccumulationDistribution',
                    field: 'Close',
                    yAxisName: 'y',
                    fill: '#6063ff',
                    period: 3,
                    animation: {enable: true}
                }],
                crosshair: {enable: true, lineType: 'Vertical'},
                tooltip: {
                    enable: true,
                    shared: true,
                    header: '<b>${point.x}</b>',
                    format: '<b>${point.y}</b>',
                },
                selectedPage: 'Charts',
                grdActions: [
                    {
                        text: 'Export'
                    },
                ],
                graphs: ['Surface Temp'],
                selectedGraphs: [{
                    text: 'Surface Temp',
                    value: 'Surface Temp'
                }],
                graphOptions: [
                    {
                        text: 'Surface Temp',
                        value: 'Surface Temp',
                        color: 'gray',
                    },
                    {
                        text: 'Air Temp',
                        value: 'Air Temp',
                        color: 'blue',
                    },
                    {
                        text: 'Dew Point',
                        value: 'Dew Point',
                        color: 'green',
                    },
                    {
                        text: 'Humidity',
                        value: 'Humidity',
                        color: 'red',
                    }
                ],
                axes: [
                    {
                        visible: true,
                        opposedPosition: true,
                        labelFormat: '{value}°',
                        // labelStyle: {color: 'transparent'},
                        name: 'primaryYAxisTemp'
                    },
                    {
                        visible: true,
                        labelFormat: '{value}%',
                        // labelStyle: {color: 'transparent'},
                        name: 'primaryYAxisHumidity'
                    },
                    {
                        visible: true,
                        labelFormat: '{value}',
                        // labelStyle: {color: 'transparent'},
                        name: 'primaryYAxisDiagnosticCode'
                    },
                ],
                searchStartDateTime: moment().subtract(1,'day').toISOString(),
                searchEndDateTime: moment().toISOString(),
                includeAllTransmissions: false,
                signedInUserIsSuperAdmin: false,
                showDiagnosticCodes: false
            }
        },
        methods: {
            mnuChartsOnClick() {
				
            },
            mnuReadingsOnClick() {
				
            },
            btnCancelOnClick() {
				mixpanel.track('location_view', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'click_source': 'history_cancel' })
                this.modalRef.hide();
            },
            grdActionOnClick(args) {
                if(args.item.text === 'Edit') {

                }
            },
            selectedGraphsOnChange($event) {
                if($event.name === 'select') {
                    this.selectedGraphs.push($event.itemData);
                }

                if($event.name === 'removed') {
                    this.selectedGraphs = this.selectedGraphs.filter(h => h.value !== $event.itemData.value);
                }

                this.showDiagnosticCodes = false;

                this.componentKey++;
            },
            grdSensorReadingsRowOnClick(args) {
                // this.selectedRow = args;
                //
                // eventBus.$emit('showAppModal', this, 'sensorsViewModal', {
                //     mode: 'viewFromHistory',
                //     sensor: this.sensor,
                //     selectedReading: args.rowData,
                //     callback: () => {
                //
                //     }
                // });
            },
            grdSensorReadingsActionOnClick(args) {
                if(args.item.text === 'Edit') {

                }

                if(args.item.text === 'Delete') {

                }
            },
            defineColumns() {
                let sensor = this.sensor;
                if(window.innerWidth < 666) {
                    this.grdSensorReadingsFields = [
                        {
                            key: 'ReadingDateTimeUTC',
                            label: 'Time',
                            type: 'custom',
                            width: '180px',
                            formatter(field, data) {
                                return data.DisplayDateTime;
                            }
                        },
                        {
                            key: 'AirTemp',
                            label: 'A',
                            type: 'custom',
                            width: '70px',
                            format: {format: "####.#'°'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'SurfaceTemp',
                            label: 'G',
                            type: 'custom',
                            width: '70px',
                            format: {format: "####.#'°'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'DewPoint',
                            label: 'D',
                            type: 'custom',
                            width: '70px',
                            format: {format: "####.#'°'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'Humidity',
                            label: 'H',
                            type: 'custom',
                            width: '70px',
                            format: {format: "####.#'%'"},
                            textAlign: 'Right'
                        },
                    ];
                } else {
                    this.grdSensorReadingsFields = [
                        {
                            key: 'ReadingDateTimeUTC',
                            label: 'Time',
                            type: 'custom',
                            formatter: (field, data) => {
                                return data.DisplayDateTime;
                            },
                            width: '180px'
                        },
                        {
                            key: 'AirTemp',
                            label: 'Air Temp (' + this.tempUnit + ')',
                            width: '100px',
                            type: 'custom',
                            format: {format: "####.#'°'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'SurfaceTemp',
                            label: 'Surface Temp (' + this.tempUnit + ')',
                            width: '130px',
                            type: 'custom',
                            format: {format: "####.#'°'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'DewPoint',
                            label: 'Dew Point (' + this.tempUnit + ')',
                            width: '110px',
                            type: 'custom',
                            format: {format: "####.#'°'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'Humidity',
                            label: 'Humidity',
                            width: '90px',
                            type: 'custom',
                            format: {format: "####.#'%'"},
                            textAlign: 'Right'
                        },
                        {
                            key: 'Photo',
                            label: 'Photo',
                            allowSorting: false,
                            type: 'template',
                            template: () => {
                                return {
                                    template: Vue.component('columnTemplate', {
                                        template: `<div class="dm-flex-row dm-align-items-center"><img v-if="image" :src="image" width="100px"><i v-if="image" @click="btnDownloadImageOnClick" class="fa fa-download dm-padding-left-sm dm-pointer"></i></div>`,
                                        methods:{
                                            btnDownloadImageOnClick() {
                                                mixpanel.track('image_download', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': sensor.ID, 'image_url': this.data.ImageUrl });

												let fileName = sensor.Name + '_' + this.data.DisplayDateTime.replace('&nbsp;&nbsp;&nbsp;&nbsp;', '_').replace('&nbsp;&nbsp;&nbsp;&nbsp;', '-') + '.jpg';
                                                fileName = fileName.replaceAll(' ', '-').replaceAll(',', '').replaceAll(':', '');

                                                axios({
                                                    method: 'GET',
                                                    url: this.data.ImageUrl,
                                                    responseType: 'blob',
                                                    params: {
                                                        v: moment().toISOString()
                                                    }
                                                })
                                                    .then(response => {
                                                        const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
                                                        let a = document.createElement("a");
                                                        document.body.appendChild(a);
                                                        a.href = objectUrl;
                                                        a.download = fileName;
                                                        a.click();
                                                        document.body.removeChild(a);
                                                    });
                                            },
                                        },
                                        computed: {
                                            image: function() {
                                                return this.data.ImageUrl ? this.data.ImageUrl : '';
                                            }
                                        }
                                    }),
                                }
                            },
                            width: '140px',
                        },
                    ];

                    if(app.signedInUser.IsSuperAdmin) {
                        this.grdSensorReadingsFields.push({
                            key: 'LoRaSignalStrength',
                            label: 'LoRa Signal Strength'
                        });

                        this.grdSensorReadingsFields.push({
                            key: 'DiagnosticCode',
                            label: 'Diagnostic Code'
                        });
                    }
                }
            },
            btnCloseOnClick() {
                this.modalRef.hide();
            },
            searchOnClick() {
				mixpanel.track('history_search', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'start_date': moment(this.searchStartDateTime).DateTime, 'end_date': moment(this.searchEndDateTime).DateTime, 'include_all_transmissions': this.includeAllTransmissions })
			
                eventBus.$emit('sensorReadingSearch', {
                    start: moment(this.searchStartDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                    end: moment(this.searchEndDateTime).format('YYYY-MM-DDTHH:mm:ss'),
                    includeAllTransmissions: this.includeAllTransmissions
                });
            },
            btnDownloadOnClick() {
				mixpanel.track('history_download', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'start_date': moment(this.searchStartDateTime).DateTime, 'end_date': moment(this.searchEndDateTime).DateTime, 'include_all_transmissions': this.includeAllTransmissions })
			
                axios({
                    method: 'GET',
                    url: window.apiRoot + '/Location/DownloadHistory',
                    params: {
                        id: this.sensor.ID,
                        start: this.searchStartDateTime ? moment(this.searchStartDateTime).format('YYYY-MM-DDTHH:mm:ss') : undefined,
                        end: this.searchEndDateTime ? moment(this.searchEndDateTime).format('YYYY-MM-DDTHH:mm:ss') : undefined,
                        includeAllTransmissions: this.includeAllTransmissions
                    },
                    headers: {Authorization: 'Bearer ' + DM.authToken},
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        let content = response.headers['content-disposition'];
                        let filename = content.split(';')[1].replace('filename=', '').replace('"', '').replace('"', '').trim();
                        this.downloadAjaxResponseAsFile(response, filename);
                    })
                    .catch((response) => {
                        try {
                            response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.response.data)));
                        } catch (err) {

                        }

                        let errorString = response.data.Error ? response.data.Error : "An unexpected error has occurred on the server.";
                        DM.alertShow(errorString, 'Error');
                    })
            },
            overlayClick() {
                this.modalRef.hide();
            },
            downloadAjaxResponseAsFile(response, fileName) {
                let blob = new Blob([response.data], {type: 'text/csv'});
                let objectUrl = URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                document.body.removeChild(a);
            },
        },
        computed: {
            palettes() {
                let colors = [];

                this.graphOptions.forEach(graph => {
                    let selectedGraph = this.selectedGraphs.filter(o => o.text === graph.text)[0];
                    if(selectedGraph) {
                        colors.push(graph.color);
                        colors.push(graph.color);
                    }
                });
                
                return colors;
            },
            primaryXAxis() {
                let stripLine = {};
                for(let i = 0; i < this.sensor.SensorReadings.length; i++) {
                    if(this.sensor.SensorReadings[i].IsForecast) {
                        stripLine = {
                            start: this.sensor.SensorReadings[i].chartDate,
                            end: this.sensor.SensorReadings[this.sensor.SensorReadings.length - 1].chartDate,
                            color: '#000',
                            visible: true,
                            opacity: '0.08'
                        };
                        break;
                    }
                }

                return {
                    visible: true,
                    color: '#5fb1f6',
                    valueType: 'DateTime',
                    labelFormat: 'MMM d h:mm a',
                    intervalType: 'Hours',
                    // crosshairTooltip: { enable: true },
                    // color: '#5fb1f6',
                    stripLines: [
                        stripLine
                    ]
                }
            },
            surfaceTempData() {
				mixpanel.track('surface_temp_chart_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'start_date': this.searchStartDateTime, 'end_date': this.searchEndDateTime })
			
                // console.log(new Date(moment(this.searchEndDateTime).endOf('minute').format('YYYY-MM-DDTHH:mm:ss')));
                // console.log(new Date(moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss')));
                // console.log(new Date(moment(this.searchEndDateTime).endOf('minute').format('YYYY-MM-DDTHH:mm:ss')) < new Date(moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss')));
                if(new Date(moment(this.searchEndDateTime).endOf('minute').format('YYYY-MM-DDTHH:mm:ss')) < new Date(moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss'))) {
                    return this.modalData.sensor.SensorReadings.filter(r => r.SurfaceTemp && !r.IsForecast);
                } else {
                    return this.modalData.sensor.SensorReadings.filter(r => r.SurfaceTemp);
                }
            },
            airTempData() {
				mixpanel.track('air_temp_chart_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'start_date': this.searchStartDateTime, 'end_date': this.searchEndDateTime })
			
                if(new Date(moment(this.searchEndDateTime).endOf('minute').format('YYYY-MM-DDTHH:mm:ss')) < new Date(moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss'))) {
                    return this.modalData.sensor.SensorReadings.filter(r => r.AirTemp && !r.IsForecast);
                } else {
                    return this.modalData.sensor.SensorReadings.filter(r => r.AirTemp);
                }
            },
            dewPointData() {
				mixpanel.track('dew_point_chart_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'start_date': this.searchStartDateTime, 'end_date': this.searchEndDateTime })
			
                if(new Date(moment(this.searchEndDateTime).endOf('minute').format('YYYY-MM-DDTHH:mm:ss')) < new Date(moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss'))) {
                    return this.modalData.sensor.SensorReadings.filter(r => r.DewPoint && !r.IsForecast);
                } else {
                    return this.modalData.sensor.SensorReadings.filter(r => r.DewPoint);
                }
            },
            humidityData() {
				mixpanel.track('humidity_chart_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'start_date': this.searchStartDateTime, 'end_date': this.searchEndDateTime })
			
                if(new Date(moment(this.searchEndDateTime).endOf('minute').format('YYYY-MM-DDTHH:mm:ss')) < new Date(moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss'))) {
                    return this.modalData.sensor.SensorReadings.filter(r => r.Humidity && !r.IsForecast);
                } else {
                    return this.modalData.sensor.SensorReadings.filter(r => r.Humidity);
                }
            }
        },
        watch: {
            selectedPage: function(newVal) {
                if(newVal === 'Charts') {
					mixpanel.track('charts_tab_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID })
				
                    setTimeout(() => {
                        document.querySelector('#chart-container div').scrollLeft = 1000;
                    },100);
                }
				else {
					mixpanel.track('readings_tab_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID })
				}
            },
            showDiagnosticCodes: function(newVal) {
                if(newVal) {
                    this.graphs = this.graphs.filter(g => g === 'Diagnostic Code');
                    this.selectedGraphs = [];
                }

                this.componentKey++;
            },
        },
        created() {
            this.signedInUserIsSuperAdmin = app.signedInUser.IsSuperAdmin;

            this.sensor = this.modalData.sensor;

            if(this.modalData.params) {
                this.searchStartDateTime = this.modalData.params.start;
                this.searchEndDateTime = this.modalData.params.end;
                this.includeAllTransmissions = this.modalData.params.includeAllTransmissions;
            } else {
                this.searchStartDateTime = moment.utc().utcOffset(this.sensor.timeZoneOffset).subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss');
                this.searchEndDateTime = moment.utc().utcOffset(this.sensor.timeZoneOffset).format('YYYY-MM-DDTHH:mm:ss');
            }

            this.tempUnit = this.sensor.GroupTemperatureUnits.Key[0];
            this.defineColumns();
        },
        mounted() {
            let defineColumns;
            window.onresize = () => {
              clearTimeout(defineColumns);
              defineColumns = setTimeout(this.defineColumns, 100);
            };
        },
    }
</script>
