export default {
    signedInUser: null,
    localStorageAuthTokenName: 'signIn.FrostDevicePortalAuthToken',
    localStorageUserIDName: 'signIn.FrostDevicePortalUserID',
    googleMapsApiKey: 'AIzaSyASk2PAA145NpiHZz_QwLPsOp8a9DTtqE8',
    userID: null,
    getUserPosition(resolve, reject) {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    resolve(position.coords);
                },
                error => {
                    reject({
                        error: 'We could not find your location. You may need to allow location services for your browser or application.'
                    });
                },
                {
                    timeout: 10000,
                }
            );
        } else {
            DM.alertShow('Geolocation is not enabled on this browser');
            reject({
                error: 'Geolocation is not enabled on this browser.'
            });
        }
    },
    openViewLocationModal(locationID, timeZoneOffset, clickSource, scope) {
        // if(location.SensorReadings.length === 0) {
        //     DM.alertShow('This location has no readings.');
        //     return;
        // }

		mixpanel.track('location_view', {'user_id': app.userID, 'location_id': locationID, 'click_source': clickSource })

        DM.http({
            method: 'GET',
            url: '/Location/GetByID',
            params: {
                id: locationID
            }
        })
            .then(response => {
                response.timeZoneOffset = timeZoneOffset;

                response.SensorReadings.forEach((r, index) => {
                    r.chartDate = new Date(moment(r.ReadingDateTimeGroupTimeZone).toISOString());
                    // console.log(r.ReadingDateTimeGroupTimeZone, moment(r.ReadingDateTimeGroupTimeZone), r.chartDate);
                    app.getDisplayTime(r, index === response.SensorReadings.length - 1);
                });

                eventBus.$emit('showAppModal', scope, 'sensorsViewModal', {
                    mode: 'view',
                    sensor: response,
                    callback: () => {}
                });
            });
    },
    getDisplayTime(reading, isMostRecent) {
        if(isMostRecent) {
            if(reading.MinutesSinceReading > 60) {
              reading.displayTime = reading.DisplayDateTime;
            } else {
              reading.displayTime = 'Last: ' + reading.MinutesSinceReading + 'm ago';
            }
        } else {
            reading.displayTime = reading.DisplayDateTime;
        }
    },
    camelPad(str) {
        if(!str) {
            return '';
        }
        return str
            // Look for long acronyms and filter out the last letter
            .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
            // Look for lower-case letters followed by upper-case letters
            .replace(/([a-z\d])([A-Z])/g, '$1 $2')
            // Look for lower-case letters followed by numbers
            .replace(/([a-zA-Z])(\d)/g, '$1 $2')
            .replace(/^./, function (str) {
                return str.toUpperCase();
            })
            // Remove any white space left around the word
            .trim();
      },
};
