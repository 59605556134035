import Vue from 'vue'
import App from './App.vue';

import dmAlertModal from '../dmFramework/dmJS/dmAlertModal';
import dmConfirmModal from '../dmFramework/dmJS/dmConfirmModal';
import dmTable from '../dmFramework/dmJS/dmTable.vue';
import DM from '../dmFramework/dmJS/dmUtils';
import app from './js/app.js';
import axios from 'axios'
import moment from 'moment'
import mixpanel from 'mixpanel-browser'

import {L10n} from '@syncfusion/ej2-base';
import {ButtonPlugin, CheckBoxPlugin} from '@syncfusion/ej2-vue-buttons';
import {MenuPlugin, AccordionPlugin, TabPlugin, Accordion, ExpandEventArgs, AccordionClickArgs} from '@syncfusion/ej2-vue-navigations';
import {GridPlugin, Sort, Group} from '@syncfusion/ej2-vue-grids';
import {DialogPlugin} from '@syncfusion/ej2-vue-popups';
import {TextBoxPlugin, SliderPlugin} from '@syncfusion/ej2-vue-inputs';
import {DatePickerPlugin, DateTimePickerPlugin, TimePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import {DropDownButtonPlugin} from "@syncfusion/ej2-vue-splitbuttons";
import {DropDownListPlugin, MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {MultiSelect, CheckBoxSelection} from '@syncfusion/ej2-dropdowns';
import {ChartPlugin} from '@syncfusion/ej2-vue-charts';
import {UploaderPlugin} from '@syncfusion/ej2-vue-inputs';

import './css/syncfusion-material.all.css'
import './css/app.css'
import './css/syncFusion.css'
import '../dmFramework/dmCSS/dmGeneral.css'
import '../dmFramework/dmCSS/dmSizing.css'
import '../dmFramework/dmCSS/dmDisplay.css'
import '../dmFramework/dmCSS/dmLayout.css'
import '../dmFramework/dmCSS/dmColors.css'
import '../dmFramework/dmCSS/dmIcons.css'
import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome.min.css'
import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome5_15_3.min.css'
import '@mdi/font/css/materialdesignicons.css'
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-vue-buttons/styles/material.css";
import "@syncfusion/ej2-vue-navigations/styles/material.css";
import "@syncfusion/ej2-vue-grids/styles/material.css";
import "@syncfusion/ej2-vue-popups/styles/material.css";
import "@syncfusion/ej2-vue-inputs/styles/material.css";
import "@syncfusion/ej2-vue-calendars/styles/material.css";
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import '@syncfusion/ej2-vue-dropdowns/styles/material.css';

Vue.component('dmAlertModal', dmAlertModal);
Vue.component('dmConfirmModal', dmConfirmModal);
Vue.component('dmTable', dmTable);

mixpanel.init('3d9baeb459c45ccb72d2b5265c00f46e', {debug: true, api_host: "https://api.mixpanel.com"})

window.DM = DM.utils;
window.app = app;
window.axios = axios;
window.moment = moment;
window.mixpanel = mixpanel;

L10n.load({
    'en-US': {
        'grid': {
            'EmptyRecord': 'No records to display.',
        },
    }
});

Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(MenuPlugin);
// Vue.use(SidebarPlugin);
Vue.use(AccordionPlugin);
Vue.use(TabPlugin);
Vue.use(GridPlugin);
Vue.use(DialogPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(TimePickerPlugin);
// Vue.use(DateRangePickerPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(ChartPlugin);
Vue.use(SliderPlugin);
Vue.use(UploaderPlugin);
MultiSelect.Inject(CheckBoxSelection);

let projectKey = window.delmarMessagingProjectKey;
let ignoreErrors = [
    'TypeError: Cannot read property \'x\' of null',
    'TypeError: Cannot read properties of null (reading \'x\')',
    'isTooltipFitPosition',
    'attribute y: Expected length, "NaN"', 'TypeError: Cannot read properties of undefined (reading \'firstChild\')'
];

let sendErrorToDelMarMessaging = (stack) => {
    if(window.environmentName === 'dev') {
        return;
    }

    let ignoreErrorFound = ignoreErrors.filter(e => stack.includes(e))[0];
    if(ignoreErrorFound) {
        return;
    }

    return axios({
        method: 'POST',
        url: 'https://dev-server.delmarsd.com/DelMarMessaging/Messaging/FrontEndLog',
        data: {
            projectKey: projectKey,
            clicks: clickTracking,
            stackTrace: stack
        }
    })
        .then(response => {
            return response.data;
        });
};

Vue.config.errorHandler = (err, vm, info) => {
    console.error(err.stack);

    sendErrorToDelMarMessaging(err.stack);
}

window.onerror = (message, source, lineno, colno, error) => {
    sendErrorToDelMarMessaging(error.stack);
};

let clickTracking = [];
window.addEventListener('click', ($event) => {
    clickTracking.push($event.target.outerHTML);
    if (clickTracking.length > 10) {
        clickTracking.shift();
    }
});

window.eventBus = new Vue();

import sidebarSensorComponent from './components/sidebarSensorComponent/sidebarSensorComponent'
Vue.component('sidebar-sensor-component', sidebarSensorComponent);

new Vue({
    el: '#app',
    render: h => h(App)
});
