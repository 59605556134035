<template src="./sensorsViewModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'sensors-view-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'sensorsViewModal' + DM.getRandomGuid(),
                lblTitle: '',
                sensor: {},
                sensorIndex: this.modalData.sensor.SensorReadings.filter(r => !r.IsForecast).length > 0 ? this.modalData.sensor.SensorReadings.filter(r => !r.IsForecast).length - 1 : 0,
            }
        },
        methods: {
            btnHistoryOnClick() {
				mixpanel.track('history_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID })
                
				eventBus.$emit('showAppModal', this, 'sensorsHistoryModal', {
                    mode: 'view',
                    sensor: this.sensor,
                    params: this.params,
                    callback: () =>{

                    }
                });

                // this.modalRef.hide();
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            btnRightArrowOnClick() {
                if(this.sensorIndex < this.sensor.SensorReadings.filter(r => !r.IsForecast).length-1) {
                    this.sensorIndex++;
                    this.selectedReading = this.sensor.SensorReadings.filter(r => !r.IsForecast)[this.sensorIndex];
                }
            },
            btnLeftArrowOnClick() {
                if(this.sensorIndex !== 0) {
                    this.sensorIndex--;
                    this.selectedReading = this.sensor.SensorReadings.filter(r => !r.IsForecast)[this.sensorIndex];
                }
            },
            getLocationByID() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetByID',
                    params: {
                        id: this.sensor.ID,
                        start: this.params ? this.params.start : undefined,
                        end: this.params ? this.params.end : undefined,
                        includeAllTransmissions: this.params ? this.params.includeAllTransmissions : undefined
                    }
                })
                    .then(response => {
                        response.SensorReadings.forEach((r, index) => {
                            r.chartDate = new Date(moment(r.ReadingDateTimeGroupTimeZone).toISOString());
                            // console.log(r.ReadingDateTimeGroupTimeZone, moment(r.ReadingDateTimeGroupTimeZone), r.chartDate);
                            app.getDisplayTime(r, index === response.SensorReadings.filter(r => !r.IsForecast).length - 1);
                        });
                        Object.assign(this.sensor, response);

                        this.initReadings(this.params);
                    });
            },
            initReadings(paramsAreIncluded) {
                if(!this.sensor.SensorReadings.filter(r => !r.IsForecast)[this.sensorIndex] && this.modalData.sensor.SensorReadings.filter(r => !r.IsForecast).length > 0) {
                    this.sensorIndex = this.sensor.SensorReadings.filter(r => !r.IsForecast).length - 1;
                }

                if(this.initialSensorReadingsLength === 0 && this.sensorIndex === 0 && this.sensor.SensorReadings.filter(r => !r.IsForecast)[this.sensorIndex]) {
                    this.sensorIndex = this.sensor.SensorReadings.filter(r => !r.IsForecast).length - 1;
                }

                if(paramsAreIncluded) {
                    this.sensorIndex = this.sensor.SensorReadings.filter(r => !r.IsForecast).length - 1;
                }

                this.selectedReading = this.sensor.SensorReadings.filter(r => !r.IsForecast)[this.sensorIndex] || {};
            },
            btnCloseModalOnClick() {
                this.modalRef.hide();
            },
            btnDownloadImageOnClick() {
                mixpanel.track('image_download', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'location_id': this.sensor.ID, 'image_url': this.selectedReading.ImageUrl })
				
				let fileName = this.sensor.Name + '_' + this.selectedReading.DisplayDateTime.replace('&nbsp;&nbsp;&nbsp;&nbsp;', '_').replace('&nbsp;&nbsp;&nbsp;&nbsp;', '-') + '.jpg';
                fileName = fileName.replaceAll(' ', '-').replaceAll(',', '').replaceAll(':', '');

                axios({
                    method: 'GET',
                    url: this.selectedReading.ImageUrl,
                    responseType: 'blob',
                    params: {
                        v: moment().toISOString()
                    }
                })
                    .then(response => {
                        const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = objectUrl;
                        a.download = fileName;
                        a.click();
                        document.body.removeChild(a);
                    });
            },
            overlayClick() {
                this.modalRef.hide();
            }
        },
        computed: {
            userHasFullAccess() {
                return true; // Add logic to determine user access
            }
        },
        created() {
            this.sensor = this.modalData.sensor;
            this.initialSensorReadingsLength = this.modalData.sensor.SensorReadings.filter(r => !r.IsForecast).length;

            this.initReadings();

            this.params = null;

            this.refreshTimer = setInterval(() => {
                this.getLocationByID(this.params);
            },1000 * window.dashboardRefreshIntervalInSeconds);

            eventBus.$on('sensorReadingSearch', (params) => {
                this.params = params;
                this.getLocationByID(this.params);
            });
        },
        mounted() {

        },
        beforeDestroy() {
            delete eventBus._events.sensorReadingSearch;
            clearInterval(this.refreshTimer);
        }
    }
</script>
