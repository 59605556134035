<template src="./usersEditModal.html"></template>

<script>
    import Vue from 'vue'
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'users-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'usersEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add User',
                emailAddress: '',
                password: '',
                firstName: '',
                lastName: '',
                phone: '',
                jobTitle: '',
                mode: '',
                grdGroupFields: [],
                grdGroupItems: [],
                grdGroupSortBy: {
                    columns: [
                        {field: 'Name', direction: 'Ascending'},
                    ]
                },
                grdGroupClass: '',
                grdLocationsFields: [],
                grdLocationsItems: [],
                grdLocationsSortBy: {
                    columns: [
                        {field: 'Name', direction: 'Ascending'},
                    ]
                },
                grdLocationsClass: '',
                grdLocationsGroupOptions: {
                    columns: ['GroupName'],
                    captionTemplate: '<span class="groupItems">${key}</span>'
                },
                selectionOptions: { type: 'Multiple' },
                showCloseIcon: true,
                locations: [],
                selectedLocations: [],
                isActive: true,
                isSuperAdmin: false,
                signedInUserIsSuperAdmin: false,
                signedInUserIsNotSelectedUser: false
            }
        },
        methods: {
            saveUser() {
                let url = '/User/Create';
                let data = DM.copy(this.modalData.user);

                if (this.modalData.mode === 'edit' || this.modalData.mode === 'profile') {
					mixpanel.track('edit_user_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'edit_user_id': this.modalData.user.ID })
				
                    data.ID = this.modalData.user.ID;
                    url = '/User/Update';
                }
				else {
					mixpanel.track('add_user_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				}

                Object.assign(data, {
                    Email: this.emailAddress,
                    Password: this.password,
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    PhoneNumber: this.phone,
                    JobTitle: this.jobTitle,
                    IsSuperAdmin: this.isSuperAdmin,
                    IsActive: this.isActive
                });

                if(this.mode !== 'profile') {
                    let selectedGroups = this.grdGroupItems.filter(g => g.selectedAdmin || g.selectedUserAllLocations || g.selectedUserSelectLocations);
                    data.Groups = selectedGroups.map(g => {
                        return {
                            GroupID: g.ID,
                            Permission: this.getGroupPermissionString(g),
                            Locations: this.selectedLocations.filter(l => l.GroupID === g.ID).map(l => {
                                return {
                                    LocationID: l.ID,
                                    Permission: 'FullAccess' // Need to change when image only is ready
                                }
                            })
                        }
                    });
                }

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            getGroupPermissionString(g) {
                if(g.selectedAdmin) {
                    return 'Admin';
                } else if(g.selectedUserAllLocations) {
                    return 'UserAllLocations';
                } else if(g.selectedUserSelectLocations) {
                    return 'UserSelectLocations';
                }
            },
            btnSaveOnClick() {
                this.saveUser()
                    .then(response => {
                        this.modalData.callback(response);
                        this.modalRef.hide();
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            btnOpenChangePasswordOnClick() {
                eventBus.$emit('showAppModal', this, 'usersPasswordEditModal');
            },
            grdGroupRowOnClick(data) {
                if(data.column.field === 'Admin') {
                    if(data.rowData.selectedAdmin) {
                        data.rowData.selectedAdmin = false;
                    } else {
                        data.rowData.selectedAdmin = true;
                        data.rowData.selectedUserSelectLocations = false;
                        data.rowData.selectedUserAllLocations = false;
                    }
                }

                if(data.column.field === 'UserSelectLocations') {
                    if(data.rowData.selectedUserSelectLocations) {
                        data.rowData.selectedUserSelectLocations = false;
                    } else {
                        data.rowData.selectedUserSelectLocations = true;
                        data.rowData.selectedAdmin = false;
                        data.rowData.selectedUserAllLocations = false;
                    }
                }

                if(data.column.field === 'UserAllLocations') {
                    if(data.rowData.selectedUserAllLocations) {
                        data.rowData.selectedUserAllLocations = false;
                    } else {
                        data.rowData.selectedUserAllLocations = true;
                        data.rowData.selectedAdmin = false;
                        data.rowData.selectedUserSelectLocations = false;
                    }
                }

                this.$refs.grdGroup.$refs.grdTableList.refresh();
                this.filterLocationsByGroupID();

                setTimeout(() => {
                    this.selectLocations();
                },100);
            },
            grdGroupActionOnClick(data) {

            },
            getGroupOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Group/GetAll'
                })
                    .then(response => {
                        response.forEach(group => {
                            group.selectedAdmin = false;
                            group.selectedUserSelectLocations = false;
                            group.selectedUserAllLocations = false;

                            if(this.modalData.mode === 'edit') {
                                let groupMatch = this.modalData.user.Groups.filter(g => g.GroupID === group.ID)[0];
                                if(groupMatch) {
                                    group.selectedAdmin = groupMatch.Permission === 'Admin';
                                    group.selectedUserAllLocations = groupMatch.Permission === 'UserAllLocations';
                                    group.selectedUserSelectLocations = groupMatch.Permission === 'UserSelectLocations';
                                }
                            }
                        });
                        this.grdGroupItems = response;
                        this.filterLocationsByGroupID();
                    });
            },
            grdLocationsRowOnClick(data) {
                setTimeout(() => {
                    this.selectedLocations = this.$refs.grdLocationsList.getSelectedRecords();
                    this.selectedLocations.forEach(l => {
                        l.selectedFull = true;
                    });

                }, 100);

                // if(data.column.field === 'FullAccess') {
                //     if(data.rowData.selectedFull) {
                //         data.rowData.selectedFull = false;
                //     } else {
                //         data.rowData.selectedFull = true;
                //         data.rowData.selectedImage = false;
                //     }
                // }
                //
                // if(data.column.field === 'Image') {
                //     if(data.rowData.selectedImage) {
                //         data.rowData.selectedImage = false;
                //     } else {
                //         data.rowData.selectedImage = true;
                //         data.rowData.selectedFull = false;
                //     }
                // }
                //
                // this.$refs.grdLocations.$refs.grdTableList.refresh();
            },
            grdLocationsActionOnClick(data) {

            },
            formatColumn(field) {
                if (field.type === 'date') {
                    return {type: "date", format: "MM/dd/yyyy"};
                }

                if (field.type === 'time') {
                    return {type: "dateTime", format: "hh:mm a"};
                }

                if (field.type === 'custom') {
                    return field.format;
                }
            },
            selectLocations() {
                let rows = this.$refs.grdLocationsList.getRows();
                let indexes = [];
                rows.forEach(r => {
                    let selectedLocation = this.selectedLocations.filter(l => l.ID === r.locationID)[0];
                    if(selectedLocation) {
                        // Need to do this because SyncFusion has a bug with selecting rows when grouping is enabled
                        // This code adjusts the indexes of the location rows based on group rows
                        let offset = 0;
                        let rowToCheck = r;
                        for(let i = 0; i < document.querySelector('#userEditModalGrdLocationsList .e-gridcontent table').rows.length; i++) {
                            if(rowToCheck.previousSibling) {
                                let previousSiblingFirstChild = rowToCheck.previousSibling.firstChild;
                                if(previousSiblingFirstChild.classList.contains('e-recordplusexpand')) {
                                    offset++;
                                }

                                rowToCheck = rowToCheck.previousSibling;
                            } else {
                                break;
                            }
                        }

                        indexes.push((r.rowIndex - offset));
                    }
                });

                this.$refs.grdLocationsList.selectRows(indexes);

                setTimeout(() => { // For some reason it loads scrolled to the bottom, so start it at the top
                    document.querySelector('#userEditModalGrdLocationsList .e-content').scrollTop = 0;
                });
            },
            rowDataBound(rowData) {
                rowData.row.locationID = rowData.data.ID;
                rowData.row.selectedFull = rowData.data.selectedFull;
            },
            getLocationsOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetAll'
                })
                    .then(response => {
                        response.forEach(location => {
                            location.selectedFull = false;
                            location.selectedImage = false;

                            if(this.modalData.mode === 'edit') {
                                let groupMatch = this.modalData.user.Groups.filter(g => g.GroupID === location.GroupID)[0];
                                if(groupMatch) {
                                    let locationMatch = groupMatch.Locations.filter(l => l.LocationID === location.ID)[0];
                                    if(locationMatch) {
                                        location.selectedFull = locationMatch.Permission === 'FullAccess';
                                        location.selectedImage = locationMatch.Permission === 'Image';
                                        this.selectedLocations.push(location);
                                    }
                                }
                            }
                        });

                        this.locations = response;
                        this.filterLocationsByGroupID();

                        if(this.modalData.mode === 'edit') {
                            setTimeout(() => {
                                this.selectLocations();
                            },100);
                        }
                    });
            },
            filterLocationsByGroupID() {
                let selectedGroupIDs = this.grdGroupItems.filter(g => g.selectedUserSelectLocations).map(g => g.ID);
                this.grdLocationsItems = this.locations.filter(l => selectedGroupIDs.includes(l.GroupID));
            },
            defineColumns() {
                let adminWidth = '70px';
                let userAllLocationsWidth = '90px';
                let userSelectLocationsWidth = '90px';
                if(window.innerWidth < 666) {
                    adminWidth = '55px';
                    userAllLocationsWidth = '75px';
                    userSelectLocationsWidth = '85px';
                }

                this.grdGroupFields = [
                    {
                        key: 'Name',
                        label: 'Group',
                    },
                    {
                        key: 'Admin',
                        label: 'Admin',
                        formatter: (column, data) => {
                            column.rowData = data;
                            return data.selectedAdmin ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                        },
                        textAlign: 'Center',
                        allowSorting: false,
                        width: adminWidth
                    },
                    {
                        key: 'UserAllLocations',
                        label: 'User - All Locations',
                        formatter: (column, data) => {
                            column.rowData = data;
                            return data.selectedUserAllLocations ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                        },
                        textAlign: 'Center',
                        allowSorting: false,
                        width: userAllLocationsWidth
                    },
                    {
                        key: 'UserSelectLocations',
                        label: 'User - Select Locations',
                        formatter: (column, data) => {
                            column.rowData = data;
                            return data.selectedUserSelectLocations ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                        },
                        textAlign: 'Center',
                        allowSorting: false,
                        width: userSelectLocationsWidth
                    },
                ];
            }
        },
        computed: {
            userHasBeenSelectedAsAUser() {
                return this.grdGroupItems.filter(g => g.selectedUserSelectLocations).length > 0;
            }
        },
        created() {
            this.getLocationsOptions();

            let user = this.modalData.user;
            this.mode = this.modalData.mode;
            this.signedInUserIsSuperAdmin = app.signedInUser.IsSuperAdmin;

            if(app.signedInUser.ID !== user.ID) {
                this.signedInUserIsNotSelectedUser = true;
            }

            if (this.modalData.mode === 'edit' || this.modalData.mode === 'profile') {
                if (this.modalData.mode === 'edit') {
                    this.lblTitle = 'Edit User: ' + this.modalData.user.FirstName + ' ' + this.modalData.user.LastName;
                }

                if (this.modalData.mode === 'profile') {
                    this.lblTitle = 'My Profile';

                    if (!app.signedInUser.HasUpdatedJobTitle) {
                        this.jobTitle = '';
                        this.showCloseIcon = false;

                        DM.alertShow('User profiles now require a Job Title. Please enter your Job Title to continue.', 'New Property: Job Title');
                    }
                    else {
                        this.jobTitle = user.JobTitle;
                        this.showCloseIcon = true;
                    }
                }
                else {
                    this.jobTitle = user.JobTitle;
                    this.showCloseIcon = true;
                }

                this.emailAddress = user.Email;
                this.firstName = user.FirstName;
                this.lastName = user.LastName;
                this.phone = user.PhoneNumber;
                this.isSuperAdmin = user.IsSuperAdmin;
                this.isActive = user.IsActive;
            }

            this.grdLocationsFields = [
                {
                    key: 'GroupName',
                    label: '',
                },
                {
                    key: 'Name',
                    label: 'Location',
                },
                {
                    key: 'Zone',
                    label: 'Zone',
                },
                {
                    key: 'FullAccess',
                    label: 'Full',
                    type: 'checkbox',
                    // formatter: (column, data) => {
                    //     column.rowData = data;
                    //     return data.selectedFull ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                    // },
                    allowSorting: false,
                    textAlign: 'Center',
                    width: '80px'
                },
                // {
                //     key: 'Image',
                //     label: 'Image',
                //     formatter: (column, data) => {
                //         column.rowData = data;
                //         return data.selectedImage ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                //     },
                //     allowSorting: false,
                //     width: '80px'
                // },
            ];

            if(this.signedInUserIsSuperAdmin) {
                this.getGroupOptions(true);
            } else {
                this.getGroupOptions(false);
            }

            this.defineColumns();
        },
        mounted() {
            let defineColumns;
            window.onresize = () => {
              clearTimeout(defineColumns);
              defineColumns = setTimeout(this.defineColumns, 100);
            };

            if (this.modalData.mode === 'profile' && !app.signedInUser.HasUpdatedJobTitle) {
                this.showCloseIcon = false;
            } else {
                this.showCloseIcon = true;
            }
        }
    }
</script>
