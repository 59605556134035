<template src="./app.html"></template>

<script>
    import dmAppMixin from "../dmFramework/dmJS/dmAppMixin";
    import signInPage from './components/signInPage/signInPage'
    import usersPage from './components/usersPage/usersPage'
    import usersEditModal from './components/usersEditModal/usersEditModal'
    import usersPasswordEditModal from './components/usersPasswordEditModal/usersPasswordEditModal'
    import groupsPage from './components/groupsPage/groupsPage'
    import groupsEditModal from './components/groupsEditModal/groupsEditModal'
    import sensorsPage from './components/sensorsPage/sensorsPage'
    import locationsEditModal from './components/locationsEditModal/locationsEditModal'
    import sensorsViewModal from './components/sensorsViewModal/sensorsViewModal'
    import sensorsHistoryModal from './components/sensorsHistoryModal/sensorsHistoryModal'
    import settingsPage from './components/settingsPage/settingsPage'
    import alertsPage from './components/alertsPage/alertsPage'
    import alertsEditModal from './components/alertsEditModal/alertsEditModal'
    import reportsPage from './components/reportsPage/reportsPage'
    import reportsEditModal from './components/reportsEditModal/reportsEditModal'
    import qrCodeScanModal from './components/qrCodeScanModal/qrCodeScanModal'
    import filesAddModal from './components/filesAddModal/filesAddModal'
    import fileAttachmentsModal from './components/fileAttachmentsModal/fileAttachmentsModal'
    import gridListViewModal from './components/gridListViewModal/gridListViewModal'

    export default {
        name: 'app',
        mixins: [
            dmAppMixin
        ],
        components: {
            signInPage,
            usersPage,
            usersEditModal,
            usersPasswordEditModal,
            groupsPage,
            groupsEditModal,
            sensorsPage,
            locationsEditModal,
            sensorsViewModal,
            sensorsHistoryModal,
            settingsPage,
            alertsPage,
            alertsEditModal,
            reportsPage,
            reportsEditModal,
            qrCodeScanModal,
            filesAddModal,
            fileAttachmentsModal,
            gridListViewModal
        },
        data: function () {
            return {
                mnuRightItems: [
                    {
                        text: '',
                        iconCss: 'mdi mdi-account-circle',
                        items: [
                            {
                                text: 'My Profile',
                                onClick: this.mnuMyProfileOnClick,
                                // iconCss: 'fa fa-user'
                            },
                            {
                                text: 'Sign Out',
                                onClick: this.mnuSignOutOnClick,
                                // iconCss: 'fa fa-sign-out'
                            },
                        ]
                    }
                ],
                mnuMobileItemsOptions: [
                    {
                        text: '',
                        iconCss: 'fa fa-bars',
                        items: [
                            {
                                text: 'Dashboard',
                                onClick: this.mnuSensorsOnClick,
                            },
                            {
                                text: 'Alerts',
                                onClick: this.mnuAlertsOnClick,
                            },
                            {
                                text: 'Reports',
                                onClick: this.mnuReportsOnClick,
                            },
                            {
                                text: 'Settings',
                                onClick: this.mnuSettingsOnClick,
                            },
                            {
                                text: 'My Profile',
                                onClick: this.mnuMyProfileOnClick,
                            },
                            {
                                text: 'Sign Out',
                                onClick: this.mnuSignOutOnClick,
                            },
                        ]
                    },
                ],
                mnuMobileItems: [
                    {
                        text: '',
                        iconCss: 'fa fa-bars',
                        items: []
                    },
                ],
                mnuSupportItems: [
                    {
                        text: 'Support',
                        onClick: this.mnuSupportOnClick,
                        iconCss: 'fa fa-question-circle'
                    },
                ],
                tmpMainTemplateName: '',
                signedInUser: null,
                moduleName: '',
                sidebarCollapsed: false,
                selectedPage: 'Sensors',
            }
        },
        methods: {
            toggleClick: function () {
                this.$refs.sidebarInstance.toggle();
            },
            logoOnClick() {

            },
            mnuOnClick(args) {
                if (args.item.onClick) {
                    args.item.onClick();
                }
            },
            mnuSensorsOnClick() {
                this.selectedPage = 'Sensors';
                this.loadPage('sensorsPage');
            },
            mnuReportsOnClick() {
                this.selectedPage = 'Reports';
                this.loadPage('reportsPage');
            },
            mnuAlertsOnClick() {
                this.selectedPage = 'Alerts';
                this.loadPage('alertsPage');
            },
            mnuSettingsOnClick() {
                this.selectedPage = 'Settings';
                this.loadPage('settingsPage');
            },
            mnuSignOutOnClick() {
                this.doSignOut();
            },
            mnuSupportOnClick() {
                eventBus.$emit('showAppModal', this, 'supportModal', {});
            },
            mnuMyProfileOnClick() {
                DM.http({
                    method: "GET",
                    url: "/User/GetByID",
                    params: {
                        id: app.signedInUser.ID
                    }
                })
                    .then(response => {
                        app.signedInUser = response;
                        this.signedInUser = response;

                        eventBus.$emit('showAppModal', this, 'usersEditModal', {
                            mode: 'profile',
                            user: response,
                            callback: (user) => {
                                app.signedInUser = user;
                                this.signedInUser = user;
                            }
                        });
                    });
            },
            doSignOut() {
                mixpanel.track('logout', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
                DM.http({
                    method: "GET",
                    url: "/User/LogOut",
                })
                    .then(response => {
                        this.resetUserToSignIn();
                    });
            },
            resetUserToSignIn() {
                DM.localStorageRemove(app.localStorageAuthTokenName);
                DM.localStorageRemove(app.localStorageUserIDName);
                app.signedInUser = null;
                this.signedInUser = null;
                this.modalsOpened = [];
                this.selectedPage = 'Sensors';
                this.loadPage('signInPage');
            },
            getUserByID(userID) {
                DM.http({
                    method: "GET",
                    url: "/User/GetByID",
                    params: {
                        id: userID || app.userID
                    }
                })
                    .then(response => {
                        app.signedInUser = response;
                        this.signedInUser = response;
                        // this.mnuRightItems[0].text = response.FirstName + ' ' + response.LastName;
                        setTimeout(() => {
                            this.mnuMobileItems[0].items = this.mnuMobileItemsOptions[0].items;

                            if (!app.signedInUser.IsSuperAdmin && response.Groups.filter(g => g.Permission === 'Admin').length === 0) {
                                this.mnuMobileItems[0].items = this.mnuMobileItemsOptions[0].items.filter(i => i.text !== 'Settings');
                            }

                            this.loadPage('sensorsPage');
                            if (!this.signedInUser.HasUpdatedJobTitle) {
                                this.mnuMyProfileOnClick();
                            }

                            // this.mnuSettingsOnClick();
                        }, 300);
                    })
            },
        },
        computed: {
            showSettings() {
                if(this.signedInUser.IsSuperAdmin) {
                    return true;
                }

                return this.signedInUser.Groups.filter(g => g.Permission === 'Admin').length > 0;
            }
        },
        created() {

        },
        mounted: function () {
            eventBus.$on('setSignedInUser', (userData) => {
                this.getUserByID(userData.UserID);
            });

            eventBus.$on('resetUserToSignIn', (userData) => {
                // This is called when a 401 is returned
                this.resetUserToSignIn();
            });

            let authToken = DM.localStorageGet(app.localStorageAuthTokenName);
            let userID = DM.localStorageGet(app.localStorageUserIDName);
            if (authToken && userID) {
                DM.authToken = authToken;
                app.userID = userID;
                this.getUserByID();
            } else {
                mixpanel.track('login_page_landing')
                this.loadPage('signInPage');
            }
        }
    }
</script>