<template>
    <ejs-dialog
        :id="modalName"
        :target="sfTarget"
        :ref="modalName"
        :header="title"
        :isModal="true"
        :visible="false"
        :allowDragging="true"
        :width="'300px'">

        <div class="flex-column">
            <div class="dm-modal-form-content">
                {{ message }}
            </div>

            <div class="dm-flex-row dm-justify-content-flex-end dm-margin-top-md">
                <ejs-button
                    @click.native="btnConfirmOnClick"
                    is-primary="true">
                    {{ confirmText }}
                </ejs-button>

                <ejs-button
                    @click.native="btnCloseOnClick"
                    cssClass="e-outline dm-margin-left-md">
                    {{ closeText }}
                </ejs-button>
            </div>
        </div>
    </ejs-dialog>
</template>

<script>
    import modalMixin from './dmModalMixin'

    export default {
        name: 'dm-confirm-modal',
        mixins: [modalMixin],
        props: [
            'modalData',
        ],
        data: function () {
            return {
                modalName: 'dmConfirmModal' + DM.getRandomGuid(),
                title: this.modalData.title,
                message: this.modalData.message,
                confirmText: this.modalData.confirmText || 'Yes',
                closeText: this.modalData.closeText || 'Close'
            }
        },
        methods: {
            btnConfirmOnClick() {
                this.modalData.confirm();
                this.modalRef.hide();
            },
            btnCloseOnClick() {
                if (typeof this.modalData.deny === 'function') {
                    this.modalData.deny();
                }

                this.modalRef.hide();
            }
        }
    }
</script>