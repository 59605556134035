<template src="./locationsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'locations-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'locationsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Location',
                reading: '',
                readingOptions: [
                    {
                        text: 'Surface Temp',
                        value: 'Surface Temp',
                    },
                    {
                        text: 'Air Temp',
                        value: 'Air Temp',
                    },
                    {
                        text: 'Humidity',
                        value: 'Humidity',
                    },
                    {
                        text: 'Dew Point',
                        value: 'Dew Point',
                    },
                    {
                        text: 'Freeze Alert',
                        value: 'Freeze Alert',
                    },
                    {
                        text: 'Location Offline',
                        value: 'Location Offline',
                    }
                ],
                temperature: '',
                stepIndex: 0,
                addASecondCondition: false,
                template: function () {
                    return {
                        template: Vue.component('bindDropdown', {
                            template: `<ejs-dropdownlist id='dropdownlist' :dataSource='dropData'> </ejs-dropdownlist>`,
                            data() {
                                return {
                                    dropData: ['Order Placed', 'Processing', 'Delivered']
                                }
                            }
                        })
                    }
                },
                map: null,
                showMarker: false,
                nextWasClicked: true,
                latitude: '',
                longitude: '',
                sensorSerialNumber: '',
                cameraSerialNumber: '',
                batterySerialNumber: '',
                name: '',
                group: null,
                groupOptions: [],
                zone: '',
                locationType: null,
                locationTypeOptions: [],
                pavementType: null,
                pavementTypeOptions: [],
                mountOrientation: null,
                mountOrientationOptions: [],
                sensorHeight: '',
                locationAltitude: '',
                notes: '',
                isActive: true,
            }
        },
        methods: {
            saveLocation() {
                let url = '/Location/Create';
                let data = DM.copy(this.modalData.location);

                if (this.modalData.mode === 'edit') {
					mixpanel.track('edit_location_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'edit_location_id': this.modalData.location.ID })
				
                    data.ID = this.modalData.location.ID;
                    url = '/Location/Update';
                }
				else {
					mixpanel.track('add_location_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				}

                Object.assign(data, {
                    GroupID: this.group,
                    Name: this.name,
                    SensorSerialNumber: this.sensorSerialNumber,
                    CameraSerialNumber: this.cameraSerialNumber,
                    BatterySerialNumber: this.batterySerialNumber,
                    Latitude: this.latitude,
                    Longitude: this.longitude,
                    Type: this.locationType || '',
                    Zone: this.zone,
                    PavementType: this.pavementType || '',
                    MountOrientation: this.mountOrientation,
                    SensorHeight: this.sensorHeight,
                    LocationAltitude: this.locationAltitude,
                    IsActive: this.isActive,
                    Notes: this.notes,
                });

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            btnNextOnClick() {
                if (this.stepIndex < 2) {
                    this.stepIndex++;
                    this.setExpandedStep();
                } else {
                    this.saveLocation()
                        .then(response => {
                            this.modalData.callback();
                            this.modalRef.hide();
                        });
                }
            },
            btnBackOnClick() {
                this.stepIndex--;
                this.setExpandedStep();
            },
            setExpandedStep() {
                for (let i = 0; i < (4 - this.stepIndex); i++) {
                    this.$refs.accordion.ej2Instances.vueInstance.enableItem((4 - i), false);
                }
                this.$refs.accordion.ej2Instances.vueInstance.enableItem(this.stepIndex, true);
                this.$refs.accordion.ej2Instances.vueInstance.expandItem(true, this.stepIndex);
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            beforeExpand: function ($event) {
                let obj = this.$refs.accordion.ej2Instances;
                let ele = obj.element.querySelectorAll('.e-selected')[0];
            },
            openScanQrCodeOnClick(serialNumberName) {
                eventBus.$emit('showAppModal', this, 'qrCodeScanModal', {
                    callback: (detectedSerialNumber) => {
                        this[serialNumberName] = detectedSerialNumber;
                    }
                });
            },
            getGroupOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Group/GetAll',
                }).then(response => {
                    this.groupOptions = response.map(g => {
                        return {
                            text: g.Name,
                            value: g.ID
                        }
                    });
                });
            },
            getLocationTypeOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetTypes',
                }).then(response => {
                    this.locationTypeOptions = response.map(g => {
                        return {
                            text: g.Key,
                            value: g.Value
                        }
                    });
                });
            },
            getPavementTypeOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetPavementTypes',
                }).then(response => {
                    this.pavementTypeOptions = response.map(g => {
                        return {
                            text: g.Key,
                            value: g.Value
                        }
                    });
                });
            },
            getMountOrientationOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetMountOrientations',
                }).then(response => {
                    this.mountOrientationOptions = response.map(g => {
                        return {
                            text: g.Key,
                            value: g.Value
                        }
                    });
                });
            },
            allowLocationServices() {
                DM.addRequest();
                return new Promise(app.getUserPosition)
                    .then(position => {
                        DM.removeRequest();
                        return {
                            lat: position.latitude,
                            lng: position.longitude
                        };
                    })
                    .catch(error => {
                        DM.removeRequest();
                        DM.alertShow('We could not find your location. You may need to allow location services for your browser or application.');
                        return Promise.reject();
                    });
            },
            getCenterLocation() {
                if (this.modalData.mode === 'edit') {
                    // console.log('edit mode return data');
                    return Promise.resolve({lat: this.latitude, lng: this.longitude});
                } else {
                    return this.allowLocationServices()
                        .then((latLng) => {
                            // console.log('LocationServices worked', latLng);
                            return latLng;
                        })
                        .catch(error => {
                            // console.log('LocationServices denied');
                            return {lat: 41.675030, lng: -86.251961};
                        });
                }
            }
        },
        computed: {
            validateCurrentStep() {
                if (this.stepIndex === 0) {
                    return this.latitude && this.longitude;
                } else if (this.stepIndex === 1) {
                    return this.sensorSerialNumber;
                } else if (this.stepIndex === 2) {
                    return this.name && this.group;
                }
            },
        },
        watch: {
            latitude(newVal) {
                let latLng = new google.maps.LatLng(newVal, this.longitude);
                if(this.map) {
                    this.map.setCenter(latLng);
                }
            },
            longitude(newVal) {
                let latLng = new google.maps.LatLng(this.latitude, newVal);
                if(this.map) {
                    this.map.setCenter(latLng);
                }
            }
        },
        created() {
            this.getGroupOptions();
            this.getLocationTypeOptions();
            this.getPavementTypeOptions();
            this.getMountOrientationOptions();

            let location = this.modalData.location;

            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Location: ' + location.Name;
                this.name = location.Name;
                this.group = location.GroupID;
                this.name = location.Name;
                this.sensorSerialNumber = location.SensorSerialNumber;
                this.cameraSerialNumber = location.CameraSerialNumber;
                this.batterySerialNumber = location.BatterySerialNumber;
                this.latitude = location.Latitude;
                this.longitude = location.Longitude;
                this.locationType = location.Type;
                this.zone = location.Zone;
                this.pavementType = location.PavementType;
                this.mountOrientation = location.MountOrientation;
                this.sensorHeight = location.SensorHeight;
                this.locationAltitude = location.LocationAltitude;
                this.isActive = location.IsActive;
                this.notes = location.Notes;
            }
        },
        mounted() {
            this.setExpandedStep();

            let initMap = (center) => {
                this.map = new google.maps.Map(document.getElementById("app-locations-map"), {
                    center: center,
                    zoom: 15,
                    mapTypeId: 'roadmap',
                    panControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    clickableIcons: false,
                    // scaleControl: true,
                    // disableDefaultUI: true
                });

                this.showMarker = true;

                google.maps.event.addListener(this.map, 'dragend', () => {
                    this.latitude = this.map.getCenter().lat();
                    this.longitude = this.map.getCenter().lng();
                });

                google.maps.event.addListener(this.map, 'zoom_changed', () => {
                    this.latitude = this.map.getCenter().lat();
                    this.longitude = this.map.getCenter().lng();
                });

                this.latitude = this.map.getCenter().lat();
                this.longitude = this.map.getCenter().lng();
            };

            if (!window.google) {
                window.mapScript = document.createElement('script');
                window.mapScript.src = 'https://maps.google.com/maps/api/js?v=3.44&key=' + app.googleMapsApiKey + '&amp;libraries=places,geometry,drawing';
                document.body.appendChild(window.mapScript);
                window.mapScript.addEventListener('load', () => {
                    this.getCenterLocation()
                        .then((latLng) => {
                            // console.log('getCenterLocation finished', latLng);
                            setTimeout(() => {
                                initMap(latLng);
                            });
                        });
                });
            } else {
                this.getCenterLocation()
                    .then((latLng) => {
                        // console.log('getCenterLocation finished', latLng);
                        setTimeout(() => {
                            initMap(latLng);
                        });
                    });
            }
        }
    }
</script>
