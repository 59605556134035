<template src="./groupsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../../dmFramework/dmJS/dmStates'

    export default {
        name: 'groups-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'groupsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Group',
                name: '',
                address: '',
                city: '',
                state: '',
                // stateOptions: states,
                zip: '',
                country: '',
                tempUnits: 'Fahrenheit',
                tempUnitsOptions: [
                    {
                        text: 'Celsius',
                        value: 'Celsius',
                    },
                    {
                        text: 'Fahrenheit',
                        value: 'Fahrenheit',
                    }
                ],
                timezone: 'Eastern Standard Time',
                timezoneOptions: [],
                isActive: true,
                apiKey: ''
            }
        },
        methods: {
            saveGroup() {
                let url = '/Group/Create';
                let data = DM.copy(this.modalData.group);

                if (this.modalData.mode === 'edit') {
					mixpanel.track('edit_group_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'edit_group_id': this.modalData.group.ID })
				
                    data.GroupID = this.modalData.group.ID;
                    url = '/Group/Update';
                }
				else {
					mixpanel.track('add_group_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				}

                Object.assign(data, {
                    Name: this.name,
                    Address: this.address,
                    City: this.city,
                    State: this.state,
                    ZipCode: this.zip,
                    Country: this.country,
                    Timezone: this.timezone || 'Eastern Standard Time',
                    TemperatureUnits: this.tempUnits,
                    IsActive: this.isActive
                });

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            btnSaveOnClick() {
                this.saveGroup()
                    .then(response => {
                        this.modalData.callback();
                        this.modalRef.hide();
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            getTimezones() {
                DM.http({
                    method: 'GET',
                    url: '/Util/GetTimeZones'
                })
                    .then(response => {
                        this.timezoneOptions = response.map(t => { return {
                            text: t.Key,
                            value: t.Value
                        }});
                    });
            }
        },
        created() {
            this.getTimezones();

            let group = this.modalData.group;

            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Group: ' + this.modalData.group.Name;
                this.name = group.Name;
                this.address = group.Address;
                this.city = group.City;
                this.state = group.State;
                this.zip = group.ZipCode;
                this.country = group.Country;
                this.timezone = group.TimeZone;
                this.tempUnits = group.TemperatureUnits;
                this.isActive = group.IsActive;
                this.apiKey = group.APIKey;
            }
        },
        mounted() {

        }
    }
</script>
