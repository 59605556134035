<template src="./reportsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import {Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar} from "@syncfusion/ej2-vue-grids";

    export default {
        name: 'reports-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        provide: {
            grid: [Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar]
        },
        data: function () {
            return {
                modalName: 'reportsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Report',
                grdLocationsFields: [],
                grdLocationsItems: [],
                grdLocationsSortBy: {
                    columns: [
                        {field: 'Name', direction: 'Ascending'},
                    ]
                },
                grdLocationsClass: 'dm-bg-color-black',
                grdPeopleFields: [],
                grdPeopleItems: [],
                grdPeopleSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdPeopleClass: '',
                stepIndex: 0,
                group: null,
                groupOptions: [],
                searchLocations: '',
                selectionOptions: { type: 'Multiple' },
                selectedLocations: [],
                searchPeople: '',
                selectedPeople: [],
                selectedReportType: null,
                reportTypes: [],
                reportTypeOptions: [],
                selectedFrequency: null,
                frequencies: [],
                frequencyOptions: [],
                selectedDayOfWeek: null,
                dayOfWeekOptions: [
                    {text: 'Sunday', value: 0},
                    {text: 'Monday', value: 1},
                    {text: 'Tuesday', value: 2},
                    {text: 'Wednesday', value: 3},
                    {text: 'Thursday',value: 4},
                    {text: 'Friday', value: 5},
                    {text: 'Saturday', value: 6}
                ],
                selectedDayOfMonth: null,
                dayOfMonthOptions: [],
                startDateTime: null,
                endDateTime: null,
                reportTypesWithTimeRangeOptions: ['History', 'ForecastAccuracy', 'SensorSummary', 'SummaryReport', 'AlertSummary', 'CertifiedSurfaceTemperature']
            }
        },
        methods: {
            saveReport() {
                let url = '/Report/Create';
                let data = DM.copy(this.modalData.report);

                if (this.modalData.mode === 'edit') {
					mixpanel.track('edit_report_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'report_id': this.modalData.report.ID, 'report_frequency': this.selectedFrequency })
				
                    data.ID = this.modalData.report.ID;
                    url = '/Report/Update';
                }
				else {
					mixpanel.track('add_report_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'report_frequency': this.selectedFrequency })
				}

                let startDate = this.startDateTime;
                let endDate = this.endDateTime;

                if(this.startDateTime && typeof this.startDateTime !== 'string') {
                    let startHours = moment(this.startDateTime).hours();
                    let startMinutes = moment(this.startDateTime).minutes();
                    startDate = moment.utc(this.startDateTime).hours(startHours).minutes(startMinutes).seconds(0).milliseconds(0).toISOString().replace('Z', '');
                }

                if(this.endDateTime && typeof this.endDateTime !== 'string') {
                    let endHours = moment(this.endDateTime).hours();
                    let endMinutes = moment(this.endDateTime).minutes();
                    endDate = moment.utc(this.endDateTime).hours(endHours).minutes(endMinutes).seconds(0).milliseconds(0).toISOString().replace('Z', '');
                }

                Object.assign(data, {
                    GroupID: this.selectedLocations[0].GroupID,
                    ReportFrequency: this.selectedFrequency,
                    ReportType: this.selectedReportType,
                    Recipients: this.selectedPeople.map(l => { return {UserID: l.ID, Phone: false, Email: true}}),
                    Locations: this.selectedLocations.map(l => { return {LocationID: l.ID}}),
                    DateRangeStart: startDate,
                    DateRangeEnd: endDate,
                    DayOfWeek: this.selectedDayOfWeek,
                    DayOfMonth: this.selectedDayOfMonth
                });

                if(this.selectedFrequency === 'Instant') {
                    return this.downloadFile(url, data);
                } else {
                    return DM.http({
                        method: 'POST',
                        url: url,
                        data: data
                    });
                }
            },
            btnNextOnClick() {
                if (this.stepIndex < 2) {
                    this.stepIndex++;
                    this.setExpandedStep();
                } else {
                    this.saveReport()
                        .then(response => {
                            this.modalData.callback();
                            this.modalRef.hide();
                        });
                }
            },
            btnDownloadReportOnClick() {
                this.saveReport()
                    .then(response => {
                        this.modalData.callback();
                        this.modalRef.hide();
                    });
            },
            btnBackOnClick() {
                this.stepIndex--;
                this.setExpandedStep();
            },
            setExpandedStep() {
                for (let i = 0; i < (3 - this.stepIndex); i++) {
                    this.$refs.accordion.ej2Instances.vueInstance.enableItem((3 - i), false);
                }
                this.$refs.accordion.ej2Instances.vueInstance.enableItem(this.stepIndex, true);
                this.$refs.accordion.ej2Instances.vueInstance.expandItem(true, this.stepIndex);
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            beforeExpand: function (ExpandEventArgs) {
                let obj = this.$refs.accordion.ej2Instances;
                let ele = obj.element.querySelectorAll('.e-selected')[0];
            },
            grdLocationsRowOnClick(data) {
                setTimeout(() => {
                    this.selectedLocations = this.$refs.grdLocationsList.getSelectedRecords();
                });
            },
            grdLocationsActionOnClick(data) {

            },
            grdPeopleRowOnClick(data) {
                setTimeout(() => {
                    this.selectedPeople = this.$refs.grdPeopleList.getSelectedRecords();
                });
            },
            grdPeopleActionOnClick(data) {

            },
            formatColumn(field) {
                if (field.type === 'date') {
                    return {type: "date", format: "MM/dd/yyyy"};
                }

                if (field.type === 'time') {
                    return {type: "dateTime", format: "hh:mm a"};
                }

                if (field.type === 'custom') {
                    return field.format;
                }
            },
            getLocations() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetAll',
                })
                    .then(response => {
                        this.locations = response;
                        this.grdLocationsItems = this.locations;

                        if(this.groupOptions.length > 0) {
                            this.btnSearchLocationsOnClick();
                        }

                        if(this.modalData.mode === 'edit') {
                            setTimeout(() => {
                                this.selectLocations();
                            },100);
                        }
                    });
            },
            selectLocations() {
                let rows = this.$refs.grdLocationsList.getRows();
                let indexes = [];
                rows.forEach(r => {
                    let selectedRow = this.modalData.report.Locations.filter(l => l.ID === r.locationID)[0];
                    if(selectedRow) {
                        indexes.push(r.rowIndex);
                    }
                });
                this.$refs.grdLocationsList.selectRows(indexes);
            },
            grdLocationsRowDataBound(rowData) {
                rowData.row.locationID = rowData.data.ID;
            },
            getGroupOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Group/GetAll',
                })
                    .then(response => {
                        this.groups = response;
                        this.groupOptions = response.map(g => {
                            return {
                                text: g.Name,
                                value: g.ID
                            }
                        });

                        if(!app.signedInUser.IsSuperAdmin) {
                            if (this.modalData.mode === 'edit') {
                                this.group = this.modalData.report.GroupID;
                            } else {
                                this.group = this.groupOptions[0].value;
                            }
                        }
                    });
            },
            grdPeopleRowDataBound(rowData) {
                rowData.row.userID = rowData.data.ID;
            },
            getPeopleOptions() {
                let params = {};
                if(this.searchParams) {
                    params = this.searchParams;
                }

                DM.http({
                    method: 'GET',
                    url: '/User/GetAll',
                    params: params
                })
                    .then(response => {
                        response.forEach(u => {
                            u.name = u.LastName + ', ' + u.FirstName;
                        });
                        this.people = response;

                        this.grdPeopleItems = this.people.filter(p => {
                            return p.Groups.filter(g => g.GroupID === this.group).length > 0;
                        });

                        if(this.modalData.mode === 'edit') {
                            setTimeout(() => {
                                this.selectPeople();
                            },200);
                        }
                    });
            },
            selectPeople() {
                let rows = this.$refs.grdPeopleList.getRows();
                let indexes = [];
                rows.forEach(r => {
                    let selectedRow = this.modalData.report.Recipients.filter(u => u.UserID === r.userID)[0];
                    if(selectedRow) {
                        indexes.push(r.rowIndex);
                    }
                });
                this.$refs.grdPeopleList.selectRows(indexes);
            },
            btnSearchLocationsOnClick() {
                this.grdLocationsItems = this.locations;

                if(this.group) {
                    this.grdLocationsItems = this.grdLocationsItems.filter(l => l.GroupID === this.group);
                }

                if(this.searchLocations) {
                    this.grdLocationsItems = this.grdLocationsItems.filter(l => l.Name.toLowerCase().includes(this.searchLocations.toLowerCase()));
                }
            },
            btnSearchPeopleOnClick() {
                this.filterPeople();
            },
            filterPeople() {
                if(this.selectedLocations.length === 0 || this.people.length === 0) {
                    return;
                }

                let selectedGroupIDs = this.selectedLocations.map(l => l.GroupID);
                if(app.signedInUser.IsSuperAdmin) {
                    this.grdPeopleItems = this.people.filter(p => {
                        return p.Groups.filter(g => selectedGroupIDs.includes(g.GroupID)).length > 0 || p.IsSuperAdmin;
                    });
                } else {
                    this.grdPeopleItems = this.people.filter(p => {
                        return p.Groups.filter(g => selectedGroupIDs.includes(g.GroupID)).length > 0;
                    });
                }

                if(this.searchPeople) {
                    this.grdPeopleItems = this.grdPeopleItems.filter(p => {
                        return p.LastName.toLowerCase().includes(this.searchPeople.toLowerCase()) || p.FirstName.toLowerCase().includes(this.searchPeople.toLowerCase())
                    });
                }
            },
            getReportTypeOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Report/GetReportTypes',
                    params: {
                        groupID: null
                    }
                })
                    .then(response => {
                        this.reportTypes = response;
                        this.reportTypeOptions = response.map(t => { return {text: t.Key, value: t.Value}});
                        this.selectedReportType = this.modalData.report.ReportType;
                    });
            },
            getFrequencyOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Report/GetReportFrequencies'
                })
                    .then(response => {
                        this.frequencies = response;
                        this.frequencyOptions = response.map(t => { return {text: t.Key, value: t.Value}});
                        this.selectedFrequency = this.modalData.report.ReportFrequency;
                    });
            },
            downloadFile(url, data) {
                DM.addRequest();
                return axios({
                    method: 'POST',
                    url: window.apiRoot + url,
                    data: data,
                    headers: {
                        Authorization: 'Bearer ' + DM.authToken
                    },
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        let blob = new Blob([response.data], {type: response.headers['content-type']});
                        let objectUrl = URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        let content = response.headers['content-disposition'];
                        let filename = content.split(';')[1].replace('filename=', '').replace('"', '').replace('"', '').trim();
                        document.body.appendChild(a);
                        a.href = objectUrl;
                        a.download = filename;
                        a.click();
                        document.body.removeChild(a);
                    },  DM.handleServerError)
                    .finally(() => {
                        DM.removeRequest();
                    });
            },
        },
        computed: {
            validateCurrentStep() {
                if(this.stepIndex === 0) {
                    return this.selectedReportType && this.selectedFrequency;
                } else if(this.stepIndex === 1) {
                    return this.selectedLocations.length > 0;
                } else if(this.stepIndex === 2) {
                    return this.selectedPeople.length > 0;
                }
            },
            canSetRecipients() {
                return this.selectedFrequency && this.frequencies.filter(f => f.Value === this.selectedFrequency)[0].CanSetRecipients;
            },
			instantFrequencyOption() {
				return [this.frequencyOptions[0]];
			}
        },
        created() {
            let report = this.modalData.report;

            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Report';
                this.startDateTime = report.DateRangeStart ? report.DateRangeStart.replace('Z', '') : null;
                this.endDateTime = report.DateRangeEnd ? report.DateRangeEnd.replace('Z', '') : null;
                this.selectedDayOfWeek = report.DayOfWeek;
                this.selectedDayOfMonth = report.DayOfMonth;
            }

            this.getGroupOptions()
                .then(response => {
                    this.getLocations();
                    this.getPeopleOptions();
                });
                
            this.getReportTypeOptions();
            this.getFrequencyOptions();

            this.$watch('selectedLocations', (newVal) => {
                this.filterPeople();
            });

            for(let i = 1; i <= 31; i++) {
                this.dayOfMonthOptions.push({text: i, value: i});
            }

            this.grdPeopleFields = [
                {
                    type: 'checkbox',
                    width: '50px'
                },
                {
                    key: 'name',
                    label: 'Person',
                },
                {
                    key: 'Email',
                    label: 'Email',
                },
            ];

            this.grdLocationsFields = [
                {
                    type: 'checkbox',
                    width: '50px'
                },
                {
                    key: 'Name',
                    label: 'Location',
                },
            ];
        },
        mounted() {
            this.setExpandedStep();
        }
    }
</script>
