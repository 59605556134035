var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-height-100"},[_c('dm-table',{ref:"grdLocations",attrs:{"table":{
            grdTableItems: _vm.grdLocationsItems,
            grdTableRowOnClick: _vm.grdLocationsRowOnClick,
            grdTableActionOnClick: _vm.grdLocationsActionOnClick,
            grdTableSortBy: _vm.grdLocationsSortBy,
            grdTableFields: _vm.grdLocationsFields,
            class: _vm.grdLocationsClass
        },"items":_vm.grdLocationsItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }