<template src="./alertsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import {Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar} from "@syncfusion/ej2-vue-grids";

    export default {
        name: 'alerts-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        provide: {
            grid: [Sort, CommandColumn, Aggregate, PdfExport, ExcelExport, Group, Toolbar]
        },
        data: function () {
            return {
                modalName: 'alertsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Alert',
                grdLocationsFields: [],
                grdLocationsItems: [],
                grdLocationsSortBy: {
                    columns: [
                        {field: 'Name', direction: 'Ascending'},
                    ]
                },
                grdLocationsClass: 'dm-bg-color-black',
                grdPeopleFields: [],
                grdPeopleItems: [],
                grdPeopleSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdPeopleClass: '',
                conditions: [{}],
                readingOptions: [],
                thresholdOptions: [],
                operatorType: null,
                operatorTypeOptions: [],
                stepIndex: 0,
                addASecondCondition: false,
                grdAlertsActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Delete'
                    }
                ],
                template: function () {
                    return {
                      template: Vue.component('bindDropdown', {
                        template: `<ejs-dropdownlist id='dropdownlist' :dataSource='dropData'> </ejs-dropdownlist>`,
                        data() {
                          return {
                            dropData: ['Order Placed', 'Processing', 'Delivered']
                          }
                        }
                      })
                    }
                  },
                searchPeople: '',
                group: null,
                groupOptions: [],
                searchLocations: '',
                selectionOptions: { type: 'Multiple' },
                selectedLocations: [],
                onlySendBetween: false,
                onlySendBetweenStartTime: null,
                onlySendBetweenEndTime: null,
                sendAfterNumberOfHoursBetweenAlerts: false,
                selectedSendAfterNumberOfHoursBetweenAlerts: null,
                sendAfterNumberOfHoursBetweenAlertsOptions: [{
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: '4',
                    value: 4
                },
                {
                    text: '5',
                    value: 5
                },
                {
                    text: '6',
                    value: 6
                }],
                sendAfterNumberOfTransmissions: false,
                selectedSendAfterNumberOfTransmissions: null,
                sendAfterNumberOfTransmissionsOptions: [{
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: '4',
                    value: 4
                },
                {
                    text: '5',
                    value: 5
                }],
                sendAfterConditionsIncludedInFutureForecast: false,
                selectedSendAfterConditionsIncludedInFutureForecast: null,
                sendAfterConditionsIncludedInFutureForecastOptions: [{
                    text: '15 mins.',
                    value: 15
                },
                {
                    text: '30 mins.',
                    value: 30
                },
                {
                    text: '45 mins.',
                    value: 45
                }],
                readings: [],
                selectedReadingIsPreset: false
            }
        },
        methods: {
            saveAlert() {
                let url = '/Alert/Create';
                let data = DM.copy(this.modalData.alert);

                if (this.modalData.mode === 'edit') {
					mixpanel.track('edit_alert_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'edit_alert_id': this.modalData.alert.ID })
				
                    data.ID = this.modalData.alert.ID;
                    url = '/Alert/Update';
                }
				else {
					mixpanel.track('add_alert_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				}

                let recipients = this.grdPeopleItems.filter(p => p.selectedEmail || p.selectedPhone).map(p => { return {UserID: p.ID, Phone: p.selectedPhone, Email: p.selectedEmail}});
                let startDate = this.onlySendBetweenStartTime;
                let endDate = this.onlySendBetweenEndTime;

                if(typeof this.onlySendBetweenStartTime !== 'string') {
                    let startHours = moment(this.onlySendBetweenStartTime).hours();
                    let startMinutes = moment(this.onlySendBetweenStartTime).minutes();
                    startDate = moment.utc().hours(startHours).minutes(startMinutes).seconds(0).milliseconds(0).toISOString().replace('Z', '');
                }

                if(typeof this.onlySendBetweenEndTime !== 'string') {
                    let endHours = moment(this.onlySendBetweenEndTime).hours();
                    let endMinutes = moment(this.onlySendBetweenEndTime).minutes();
                    endDate = moment.utc().hours(endHours).minutes(endMinutes).seconds(0).milliseconds(0).toISOString().replace('Z', '');
                }

                Object.assign(data, {
                    GroupID: this.selectedLocations[0].GroupID,
                    Reading1: this.conditions[0].reading,
                    Threshold1: this.conditions[0].threshold,
                    Temperature1: this.conditions[0].temperature,
                    Condition1And2Operator: this.operatorType,
                    Reading2: this.conditions.length > 1 ? this.conditions[1].reading : undefined,
                    Threshold2: this.conditions.length > 1 ? this.conditions[1].threshold : undefined,
                    Temperature2: this.conditions.length > 1 ? this.conditions[1].temperature : undefined,
                    TimeRangeStart: this.onlySendBetween ? startDate : undefined,
                    TimeRangeEnd: this.onlySendBetween ? endDate : undefined,
                    NumberOfHoursBetweenAlerts: this.sendAfterNumberOfHoursBetweenAlerts ? this.selectedSendAfterNumberOfHoursBetweenAlerts : undefined,
                    NumberOfTransmissions: this.sendAfterNumberOfTransmissions ? this.selectedSendAfterNumberOfTransmissions : undefined,
                    ConditionsInForecastMinutes: this.sendAfterConditionsIncludedInFutureForecast ? this.selectedSendAfterConditionsIncludedInFutureForecast : undefined,
                    Recipients: recipients,
                    Locations: this.selectedLocations.map(l => { return {LocationID: l.ID}}),
                });

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            btnNextOnClick() {
                if (this.stepIndex < 3) {
                    this.stepIndex++;
                    this.setExpandedStep();
                } else {
                    this.saveAlert()
                        .then(response => {
                            this.modalData.callback();
                            this.modalRef.hide();
                        });
                }
            },
            btnBackOnClick() {
                this.stepIndex--;
                this.setExpandedStep();
            },
            setExpandedStep() {
                for (let i = 0; i < (4 - this.stepIndex); i++) {
                    this.$refs.accordion.ej2Instances.vueInstance.enableItem((4 - i), false);
                }
                this.$refs.accordion.ej2Instances.vueInstance.enableItem(this.stepIndex, true);
                this.$refs.accordion.ej2Instances.vueInstance.expandItem(true, this.stepIndex);
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            beforeExpand: function (ExpandEventArgs) {
                let obj = this.$refs.accordion.ej2Instances;
                let ele = obj.element.querySelectorAll('.e-selected')[0];
            },
            grdLocationsRowOnClick(data) {
                setTimeout(() => {
                    this.selectedLocations = this.$refs.grdLocationsList.getSelectedRecords();
                });
            },
            grdLocationsActionOnClick(data) {

            },
            grdPeopleRowOnClick(data) {
                if(data.column.field === 'PhoneNumber' && data.rowData.PhoneNumber) {
                    data.rowData.selectedPhone = !data.rowData.selectedPhone;
                }

                if(data.column.field === 'Email' && data.rowData.Email) {
                    data.rowData.selectedEmail = !data.rowData.selectedEmail;
                }

                this.$refs.grdPeople.$refs.grdTableList.refresh();
            },
            grdPeopleActionOnClick(data) {

            },
            formatColumn(field) {
                if (field.type === 'date') {
                    return {type: "date", format: "MM/dd/yyyy"};
                }

                if (field.type === 'time') {
                    return {type: "dateTime", format: "hh:mm a"};
                }

                if (field.type === 'custom') {
                    return field.format;
                }
            },
            getLocations() {
                DM.http({
                    method: 'GET',
                    url: '/Location/GetAll',
                }).then(response => {
                    this.locations = response;
                    this.grdLocationsItems = this.locations;

                    if(this.groupOptions.length > 0) {
                        this.btnSearchLocationsOnClick();
                    }

                    if(this.modalData.mode === 'edit') {
                        setTimeout(() => {
                            this.selectLocations();
                        },100);
                    }
                });
            },
            selectLocations() {
                let rows = this.$refs.grdLocationsList.getRows();
                let indexes = [];
                rows.forEach(r => {
                    let selectedRow = this.modalData.alert.Locations.filter(l => l.ID === r.locationID)[0];
                    if(selectedRow) {
                        indexes.push(r.rowIndex);
                    }
                });
                this.$refs.grdLocationsList.selectRows(indexes);
            },
            rowDataBound(rowData) {
                rowData.row.locationID = rowData.data.ID;
            },
            getGroupOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Group/GetAll',
                }).then(response => {
                    this.groups = response;
                    this.groupOptions = response.map(g => {
                        return {
                            text: g.Name,
                            value: g.ID
                        }
                    });

                    if(this.modalData.mode === 'edit') {
                        this.group = this.modalData.alert.GroupID;
                    } else {
                        this.group = this.groupOptions[0].value;
                    }

                    if(this.grdLocationsItems.length > 0) {
                        this.btnSearchLocationsOnClick();
                    }
                });
            },
            getPeopleOptions() {
                let params = {};
                if(this.searchParams) {
                    params = this.searchParams;
                }

                DM.http({
                    method: 'GET',
                    url: '/User/GetAll',
                    params: params
                })
                    .then(response => {
                        response.forEach(u => {
                            u.name = u.LastName + ', ' + u.FirstName;
                            if(this.modalData.mode === 'edit') {
                                let selectedUser = this.modalData.alert.Recipients.filter(r => r.UserID === u.ID)[0];
                                if (selectedUser) {
                                    u.selectedPhone = selectedUser.Phone;
                                    u.selectedEmail = selectedUser.Email;
                                } else {
                                    u.selectedPhone = false;
                                    u.selectedEmail = false;
                                }
                            } else {
                                u.selectedPhone = false;
                                u.selectedEmail = false;
                            }
                        });
                        this.people = response;

                        this.grdPeopleItems = this.people.filter(p => {
                            return p.Groups.filter(g => g.GroupID === this.group).length > 0;
                        });
                    });
            },
            btnSearchLocationsOnClick() {
                this.grdLocationsItems = this.locations;

                if(this.group) {
                    this.grdLocationsItems = this.grdLocationsItems.filter(l => l.GroupID === this.group);
                }

                if(this.searchLocations) {
                    this.grdLocationsItems = this.grdLocationsItems.filter(l => l.Name.toLowerCase().includes(this.searchLocations.toLowerCase()));
                }
            },
            btnSearchPeopleOnClick() {
                this.filterPeople();
            },
            filterPeople() {
                if(this.selectedLocations.length === 0) {
                    return;
                }

                if(app.signedInUser.IsSuperAdmin) {
                    this.grdPeopleItems = this.people.filter(p => {
                        return p.Groups.filter(g => g.GroupID === this.selectedLocations[0].GroupID).length > 0 || p.IsSuperAdmin;
                    });
                } else {
                    this.grdPeopleItems = this.people.filter(p => {
                        return p.Groups.filter(g => g.GroupID === this.selectedLocations[0].GroupID).length > 0;
                    });
                }

                if(this.searchPeople) {
                    this.grdPeopleItems = this.grdPeopleItems.filter(p => {
                        return p.LastName.toLowerCase().includes(this.searchPeople.toLowerCase()) || p.FirstName.toLowerCase().includes(this.searchPeople.toLowerCase())
                    });
                }
            },
            getThresholdOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetThresholdTypes'
                })
                    .then(response => {
                        this.thresholdOptions = response.map(t => { return {text: t.Key, value: t.Value}});
                    });
            },
            getOperatorTypeOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetOperatorTypes'
                })
                    .then(response => {
                        this.operatorTypeOptions = response.map(t => { return {text: t.Key, value: t.Value}});
                    });
            },
            getReadingOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Alert/GetReadingTypes'
                })
                    .then(response => {
                        this.readings = response;
                        this.readingOptions = response.map(t => { return {text: t.Key, value: t.Value, isPreset: t.IsPreset}});

                        this.selectedReadingIsPreset = this.readings.filter(r => r.Value === this.conditions[0].reading)[0].IsPreset;
                    });
            },
            reading1Changed() {
                if(this.conditions[0].reading) {
                    let selectedReadingIsPreset = this.readings.filter(r => r.Value === this.conditions[0].reading)[0].IsPreset;
                    if (selectedReadingIsPreset) {
                        this.conditions[0].threshold = null;
                        this.conditions[0].temperature = '';
                        this.addASecondCondition = false;
                        if (this.conditions[1]) {
                            this.operatorType = null;
                            this.conditions[1].reading = null;
                            this.conditions[1].threshold = null;
                            this.conditions[1].temperature = '';
                        }
                    }

                    this.selectedReadingIsPreset = selectedReadingIsPreset;
                }
            }
        },
        computed: {
            validateCurrentStep() {
                if(this.stepIndex === 0) {
                    return this.selectedLocations.length > 0;
                } else if(this.stepIndex === 1) {
                    if(this.addASecondCondition) {
                        return this.conditions[0].reading && this.conditions[1].reading;
                    }

                    return this.conditions[0].reading;
                } else if(this.stepIndex === 2) {
                    let selectedPeople = this.people.filter(p => p.selectedPhone || p.selectedEmail);
                    return selectedPeople.length > 0;
                } else if(this.stepIndex === 3) {
                    return true;
                    // return this.onlySendBetween || this.sendAfterNumberOfHoursBetweenAlerts || this.sendAfterNumberOfTransmissions || this.sendAfterConditionsIncludedInFutureForecast;
                }
            },
            getGroupTempUnit() {
                if(this.selectedLocations.length > 0 && this.groups.length > 0) {
                    return this.groups.filter(g => g.ID === this.selectedLocations[0].GroupID)[0].TemperatureUnits[0];
                }

                return '';
            }
        },
        created() {
            let alert = this.modalData.alert;

            this.mode = this.modalData.mode;

            this.sendAfterNumberOfHoursBetweenAlerts = true;
            this.selectedSendAfterNumberOfHoursBetweenAlerts = 6;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Alert';

                Object.assign(this.conditions[0], {
                    reading: alert.Reading1,
                    threshold: alert.Threshold1,
                    temperature: alert.Temperature1
                });

                this.operatorType = alert.Condition1And2Operator;

                if(alert.Reading2) {
                    this.conditions.push({
                        reading: alert.Reading2,
                        threshold: alert.Threshold2,
                        temperature: alert.Temperature2
                    });

                    this.addASecondCondition = true;
                }

                if(alert.TimeRangeStart && alert.TimeRangeEnd) {
                    this.onlySendBetween = true;
                }
                this.onlySendBetweenStartTime = alert.TimeRangeStart ? alert.TimeRangeStart.replace('Z', '') : null;
                this.onlySendBetweenEndTime = alert.TimeRangeEnd ? alert.TimeRangeEnd.replace('Z', '') : null;

                // if(alert.NumberOfHoursBetweenAlerts) {
                //     this.sendAfterNumberOfHoursBetweenAlerts = true;
                // }
                // this.selectedSendAfterNumberOfHoursBetweenAlerts = alert.NumberOfHoursBetweenAlerts;

                if(alert.NumberOfTransmissions) {
                    this.sendAfterNumberOfTransmissions = true;
                }
                this.selectedSendAfterNumberOfTransmissions = alert.NumberOfTransmissions;

                if(alert.ConditionsInForecastMinutes) {
                    this.sendAfterConditionsIncludedInFutureForecast = true;
                }
                this.selectedSendAfterConditionsIncludedInFutureForecast = alert.ConditionsInForecastMinutes;
            }

            this.getLocations();
            this.getGroupOptions();
            this.getPeopleOptions();
            this.getThresholdOptions();
            this.getReadingOptions();
            this.getOperatorTypeOptions();

            this.$watch('addASecondCondition', (newVal) => {
                if(newVal) {
                    this.conditions.push({});
                } else {
                    this.conditions.pop();
                }
            });

            this.$watch('selectedLocations', (newVal) => {
                this.filterPeople();
            });

            this.grdPeopleFields = [
                {
                    key: 'name',
                    label: 'Person',
                },
                // {
                //     key: 'PhoneNumber',
                //     label: 'Phone',
                //     formatter: (column, data) => {
                //         column.rowData = data;
                //         if(column.rowData.PhoneNumber) {
                //             return data.selectedPhone ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                //         } else {
                //             return '<i class="far fa-square dm-pointer app-grid-checkbox dm-pointer-events-none" style="opacity: 0.3;"></i>';
                //         }
                //
                //     },
                //     width: '70px'
                // },
                {
                    key: 'Email',
                    label: 'Email',
                    formatter: (column, data) => {
                        column.rowData = data;
                        if(column.rowData.Email) {
                            return data.selectedEmail ? '<i class="fa fa-check-square dm-pointer app-grid-checkbox"></i>' : '<i class="far fa-square dm-pointer app-grid-checkbox"></i>';
                        } else {
                            return '<i class="far fa-square dm-pointer app-grid-checkbox dm-pointer-events-none" style="opacity: 0.3;"></i>';
                        }
                    },
                    width: '70px'
                },
            ];

            this.grdLocationsFields = [
                {
                    type: 'checkbox',
                    width: '50px'
                },
                {
                    key: 'Name',
                    label: 'Location',
                },
            ];
        },
        mounted() {
            this.setExpandedStep();
        }
    }
</script>
