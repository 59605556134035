<template src="./sensorsPage.html"></template>

<script>
    import Vue from 'vue'
    import sidebarZoneComponent from '../sidebarZoneComponent/sidebarZoneComponent'
    import carsImage from '../../images/cars-dark-oval-small.png'
    import carsAlertImage from '../../images/cars-alert-dark.png'
    import roadImage from '../../images/road-dark-oval-small.png'
    import roadAlertImage from '../../images/road-alert-dark.png'
    import bridgeImage from '../../images/bridge-dark-oval-small.png'
    import bridgeAlertImage from '../../images/bridge-alert-dark.png'

    export default {
        name: 'sensors-page',
        components: {
            sidebarZoneComponent
        },
        props: [
            'modalsOpened'
        ],
        data: function () {
            return {
                seriesData: [],
                groups: [],
                sensors: [],
                map: null,
                searchText: '',
                scrollCount: 0,
                layerOptions: [
                    {
                        text: 'Precipitation',
                        iconCss: 'fa fa-check-square'
                    },
                    {
                        text: 'Wind Speed & Direction',
                        iconCss: 'far fa-square'
                    },
                    {
                        text: 'Humidity',
                        iconCss: 'far fa-square'
                    },
                    {
                        text: 'Dew Point',
                        iconCss: 'far fa-square'
                    }
                ],
                mapLayerType: 'precip',
                showMap: false,
                imageMap: {
                    ParkingLot: carsImage,
                    ParkingLotAlert: carsAlertImage,
                    Road: roadImage,
                    RoadAlert: roadAlertImage,
                    Bridge: bridgeImage,
                    BridgeAlert: bridgeAlertImage,
                },
                markers: [],
                overlayTimestamp: '',
                animatedPrecipTimer: null,
                animatedPrecipOverlayAction: 'play',
                animatedPrecipSliderTicks: { placement: 'After', largeStep: 1 },
                // animatedPrecipSliderTooltip: { placement: 'Before', isVisible: true, showOn: 'Always' },
                animatedPrecipSliderValue: 1,
                animatedPrecipSliderStep: 1,
                hoursIntoFuture: 1,
                zoomLevel: 9,
                minZoomLevelForOverlay: 9
            }
        },
        methods: {
            handleScroll() {
                clearInterval(this.scrollTimer);
                this.scrollTimer = setInterval(() => {
                    this.trackScrolling();
                },5000);

                this.scrollCount += 1;
            },
            trackScrolling() {
                if (this.scrollCount != 0) {
                    mixpanel.track('dashboard_scroll', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'scrollPixels': this.scrollCount });
                    this.scrollCount = 0;
                }
            },
            getLocations(isRefresh) {
				if (!isRefresh) {
					mixpanel.track('dashboard_search_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'search_text': this.searchText });
				}
				else {
				    mixpanel.track('dashboard_auto_refresh', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'search_text': this.searchText });
				}
			
                this.groups = [];
                this.markers.forEach(m => {
                    m.setMap(null);
                });
                this.markers = [];

                DM.http({
                    method: 'GET',
                    url: '/Location/GetAllMapView',
                    params: {
                        search: this.searchText
                    }
                }, this.modalsOpened.length > 0)
                .then(response => {
                    response.forEach(group => {
                        if(app.signedInUser.Preferences) {
                            let groupPreference = app.signedInUser.Preferences.SensorMapViewDisplay.filter(p => p.Group.ID === group.GroupID)[0];
                            if(groupPreference) {
                                group.showGroup = groupPreference.Group.DisplayAsDefault;
                            } else {
                                group.showGroup = true;
                            }
                        } else {
                            group.showGroup = true;
                        }

                        group.Zones.forEach(zone => {

                            if(app.signedInUser.Preferences) {
                                let groupPreference = app.signedInUser.Preferences.SensorMapViewDisplay.filter(p => p.Group.ID === group.GroupID)[0];
                                if(groupPreference) {
                                    let zonePreference = groupPreference.Zones.filter(p => p.Name === zone.ZoneName)[0];
                                    if(zonePreference) {
                                        zone.showZone = zonePreference.DisplayAsDefault;
                                    } else {
                                        zone.showZone = true;
                                    }
                                } else {
                                    zone.showZone = true;
                                }
                            } else {
                                zone.showZone = true;
                            }

                            zone.ZoneName = zone.ZoneName ? zone.ZoneName : 'No Zone';

                            zone.Locations.forEach(location => {
                                if(!this.imageMap[location.Type.Value]) {
                                    return;
                                }

                                location.SensorReadings.forEach(r => {
                                    r.chartDate = new Date(moment(r.ReadingDateTimeGroupTimeZone).toISOString());
                                    // console.log(r.ReadingDateTimeGroupTimeZone, moment(r.ReadingDateTimeGroupTimeZone), r.chartDate);
                                });

                            });
                        });
                    });

                    this.groups = response;

                    setTimeout(() => {
                        if(this.$refs.acc) {
                            for(let i = 0; i < this.$refs.acc.length; i++) {
                                this.$refs.acc[i].groupID = this.groups[i].GroupID;

                                if(this.groups[i].showGroup) {
                                    this.$refs.acc[i].expandItem(true,0);
                                }
                            }
                        }
                    });

                    this.drawLocations(isRefresh);
                });
            },
            iconLoad(location, map, markers, callback) {
                var canvasElem = document.getElementById("iconCanvas");
                var context = canvasElem.getContext("2d");

                var img = new Image();
                img.src = this.imageMap[location.Type.Value];
                img.onload = function() {
                    var tempUnits = "°F";
                    if (location.GroupTemperatureUnits.Key == "Celsius") {
                        tempUnits = "°C";
                    }

                    var airTemp = "--.-";
                    var surfaceTemp = "--.-";
                    if (location.MostRecentTransmission) {
                        airTemp = location.MostRecentTransmission.AirTemp;
                        surfaceTemp = location.MostRecentTransmission.SurfaceTemp;
                    }

                    context.drawImage(img, 0, 0);
                    context.fillStyle = "#ffffff";
                    context.font = "14px Barlow";
                    context.fillText("A: " + airTemp + " " + tempUnits, 29, 20);
                    context.fillText("S: " + surfaceTemp + " " + tempUnits, 29, 40);
                    var iconUrl = canvasElem.toDataURL();
                    callback(iconUrl, map, markers);
                };
                return;
            },
            drawLocations(isRefresh) {
                let draw = () => {
                    let bounds = new google.maps.LatLngBounds();

                    this.groups.forEach((group, groupIndex) => {
                        group.Zones.forEach(zone => {
                            zone.Locations.forEach(location => {
                                if (groupIndex === 0) {
                                    bounds.extend({lat: location.Latitude, lng: location.Longitude});
                                }

                                this.iconLoad(location, this.map, this.markers, function(iconUrl, map, markers) {
                                    let marker = new google.maps.Marker({
                                        position: {lat: location.Latitude, lng: location.Longitude},
                                        map: map,
                                        // label: sensor.Name,
                                        icon: {
                                            url: iconUrl,
                                            anchor: new google.maps.Point(43.2, 42),
                                            //scaledSize: new google.maps.Size(85, 70)
                                            //scaledSize: new google.maps.Size(85, 70)
                                        },
                                        // opacity: 0.5
                                    });

                                    marker.addListener("click", () => {
                                        app.openViewLocationModal(location.ID, location.TimeZoneOffset, 'location_icon', this);
                                    });

                                    // Saving in case this feature id needed later - JH
                                    // if(!group.showGroup) {
                                    //     marker.setMap(null);
                                    // } else {
                                    //     if(!zone.showZone) {
                                    //         marker.setMap(null);
                                    //     } else {
                                    //         marker.setMap(this.map);
                                    //     }
                                    // }

                                    marker.groupID = group.GroupID;
                                    marker.zoneName = zone.ZoneName;

                                    markers.push(marker);
                                });
                            });
                        });
                    });

                    if(this.groups.length !== 0 && !isRefresh) {
                        this.map.fitBounds(bounds);
                        if(this.map.getZoom() > this.minZoomLevelForOverlay) {
                            this.map.setZoom(this.minZoomLevelForOverlay);
                        }
                    }
                };

                if (this.map) {
                    draw();
                } else {
                    eventBus.$on('mapIsInitialized', draw);
                }
            },
            layerSelected(data) {
                this.stopAnimatedPrecipOverlay();

                data.item.controlParent.items.forEach(l => {
                    Vue.set(l, 'iconCss', 'far fa-square');
                });

                if(data.item.text === 'Precipitation') {
					mixpanel.track('precipitation_overlay_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				
                    if(this.mapLayerType === 'precip') {
                        this.mapLayerType = '';
                    } else {
                        this.mapLayerType = 'precip';
                        data.item.iconCss = 'fa fa-check-square';
                    }
                }

                if(data.item.text === 'Wind Speed & Direction') {
					mixpanel.track('wind_speed_overlay_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				
                    if(this.mapLayerType === 'wind') {
                        this.mapLayerType = '';
                    } else {
                        this.mapLayerType = 'wind';
                        data.item.iconCss = 'fa fa-check-square';
                    }
                }

                if(data.item.text === 'Humidity') {
					mixpanel.track('humidity_overlay_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				
                    if(this.mapLayerType === 'relhum') {
                        this.mapLayerType = '';
                    } else {
                        this.mapLayerType = 'relhum';
                        data.item.iconCss = 'fa fa-check-square';
                    }
                }

                if(data.item.text === 'Dew Point') {
					mixpanel.track('dew_point_overlay_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				
                    if(this.mapLayerType === 'dewpoint') {
                        this.mapLayerType = '';
                    } else {
                        this.mapLayerType = 'dewpoint';
                        data.item.iconCss = 'fa fa-check-square';
                    }
                }

                this.map.overlayMapTypes.clear();

                if(!this.mapLayerType) {
                    return;
                }

                this.drawOverlay();
            },
            drawOverlay(timeStamp) {
                const imageMapType = new google.maps.ImageMapType({
                    getTileUrl: (coord, zoom) => {
                        if(zoom > this.minZoomLevelForOverlay && this.mapLayerType !== 'radar_precip') {
                            // Don't try to get tiles with zoom greater than min zoom level for overlay for map types except radar_precip
                            return;
                        }

                        // radar_cref   # Composite Reflectivity (currently US only)
                        // radar_precip # Dual-Pol precip
                        // precip       # Forecast precip
                        // wind         # Forecast Wind speed and direction
                        // cloud        # Forecast Cloud Cover

                        let nowUtc = timeStamp || '';
                        if(!nowUtc) {
                            if (this.mapLayerType === 'radar_precip') {
                                let nowMinusFourMinutes = moment().utc().subtract(4, 'minutes');
                                if (nowMinusFourMinutes.minutes() % 2 == 0) {
                                    nowUtc = nowMinusFourMinutes.format('YYYYMMDD.kkmm');
                                    this.overlayTimestamp = moment().subtract(4, 'minutes').format('h:mm A');
                                } else {
                                    nowUtc = moment().utc().subtract(5, 'minutes').format('YYYYMMDD.kkmm');
                                    this.overlayTimestamp = moment().subtract(5, 'minutes').format('h:mm A');
                                }
                            } else {
                                if(moment().minutes() >= 30) {
                                    nowUtc = moment().add(1, 'hour').utc().format('YYYYMMDD.HH') + '00';
                                    this.overlayTimestamp = moment().add(1, 'hour').format('h:00 A');
                                } else {
                                    nowUtc = moment().utc().format('YYYYMMDD.HH') + '00';
                                    this.overlayTimestamp = moment().format('h:00 A');
                                }
                            }
                        }

                        if(this.mapLayerType === 'radar_precip') {
                            return 'https://fcstimg.azurewebsites.net/tiled/' + this.mapLayerType + '/' + nowUtc + '/' + coord.x + '/' + coord.y + '/' + zoom + '?token=fc';
                        } else {
                            return 'https://wxtiles.azureedge.net/wxtiles/tiles/' + nowUtc + '/' + this.mapLayerType + '/' + zoom + '/' + this.mapLayerType + '.' + nowUtc + '.' + zoom + '.' + coord.x + '.' + coord.y + '.png';
                        }
                    },
                    tileSize: new google.maps.Size(256, 256),
                });

                imageMapType.mapLayerType = this.mapLayerType;
                this.map.overlayMapTypes.push(imageMapType);
                this.map.overlayMapTypes.getAt(0).setOpacity(0.5);
            },
            searchOnClick() {
                this.getLocations(false);
            },
            createRefreshTimer() {
                this.refreshTimer = setInterval(() => {
                    this.getLocations(true);
                },1000 * window.dashboardRefreshIntervalInSeconds);
            },
            createScrollTimer() {
               this.scrollTimer = setInterval(() => {
                    this.trackScrolling();
                },5000);
            },
            updateAnimatedPrecipTimestampDisplay() {
                this.overlayTimestamp = moment().add(this.hoursIntoFuture, 'hours').format('h:00 A');
            },
            animatePrecipOverlay() {
                if(this.map.overlayMapTypes.length > 0 && this.map.overlayMapTypes.getAt(0).mapLayerType !== 'precip') {
                   this.map.overlayMapTypes.clear();
                }

                if(this.animatedPrecipOverlayAction === 'play') {
					mixpanel.track('map_overlay_play_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				
                    this.animatedPrecipOverlayAction = 'pause';
                    // console.log('start interval', 'this.hoursIntoFuture', this.hoursIntoFuture, 'this.animatedPrecipSliderValue', this.animatedPrecipSliderValue);

                    this.animatedPrecipSliderValue = this.hoursIntoFuture;
                    // console.log('this.hoursIntoFuture', this.hoursIntoFuture, 'this.animatedPrecipSliderValue', this.animatedPrecipSliderValue);
                    let animatedPrecipTimestamp = moment().utc().add(this.hoursIntoFuture, 'hours').format('YYYYMMDD.HH') + '00';
                    this.updateAnimatedPrecipTimestampDisplay();
                    // this.map.overlayMapTypes.clear();
                    this.mapLayerType = 'precip';

                    if(!this.map.overlayMapTypes || this.map.overlayMapTypes.length === 0) {
                        const imageMapType = new google.maps.ImageMapType({
                            getTileUrl: (coord, zoom) => {
                                if(zoom > this.minZoomLevelForOverlay) {
                                    // Don't try to get tiles with zoom greater than min zoom level for overlay
                                    return;
                                }
                                return 'https://wxtiles.azureedge.net/wxtiles/tiles/' + animatedPrecipTimestamp + '/' + this.mapLayerType + '/' + zoom + '/' + this.mapLayerType + '.' + animatedPrecipTimestamp + '.' + zoom + '.' + coord.x + '.' + coord.y + '.png';
                            },
                            tileSize: new google.maps.Size(256, 256),
                        });

                        imageMapType.mapLayerType = this.mapLayerType;
                        this.map.overlayMapTypes.push(imageMapType);
                        this.map.overlayMapTypes.getAt(0).setOpacity(0.5);
                    }

                    // Go to next hour
                    this.hoursIntoFuture++;

                    if(this.hoursIntoFuture >= 7) {
                        this.hoursIntoFuture = 1;
                    }

                    this.animatedPrecipTimer = setInterval(() => {
                        this.animatedPrecipSliderValue = this.hoursIntoFuture;
                        // console.log('this.hoursIntoFuture', this.hoursIntoFuture, 'this.animatedPrecipSliderValue', this.animatedPrecipSliderValue);
                        let animatedPrecipTimestamp = moment().utc().add(this.hoursIntoFuture, 'hours').format('YYYYMMDD.HH') + '00';
                        this.updateAnimatedPrecipTimestampDisplay();
                        // this.map.overlayMapTypes.clear();
                        this.mapLayerType = 'precip';

                        const imageMapType = new google.maps.ImageMapType({
                            getTileUrl: (coord, zoom) => {
                                if(zoom > this.minZoomLevelForOverlay) {
                                    // Don't try to get tiles with zoom greater than min zoom level for overlay
                                    return;
                                }
                                return 'https://wxtiles.azureedge.net/wxtiles/tiles/' + animatedPrecipTimestamp + '/' + this.mapLayerType + '/' + zoom + '/' + this.mapLayerType + '.' + animatedPrecipTimestamp + '.' + zoom + '.' + coord.x + '.' + coord.y + '.png';
                            },
                            tileSize: new google.maps.Size(256, 256),
                        });

                        imageMapType.mapLayerType = this.mapLayerType;

                        // console.log(this.map.overlayMapTypes.length);

                        // If this is a newly fetched layer, push it to the overlayMapTypes array
                        if(this.map.overlayMapTypes.length !== 7) {
                            this.map.overlayMapTypes.push(imageMapType);
                        }

                        // Hide all the layers
                        for(let i = 0; i < this.map.overlayMapTypes.length; i++) {
                            this.map.overlayMapTypes.getAt(i).setOpacity(0);
                        }

                        // Show the proper layer
                        this.map.overlayMapTypes.getAt(this.hoursIntoFuture-1).setOpacity(0.5);

                        // Go to next hour
                        this.hoursIntoFuture++;
                        // If we hit hour 7, go back to 1
                        if(this.hoursIntoFuture >= 7) {
                            this.hoursIntoFuture = 1;
                        }

                        // console.log('doing interval', 'this.hoursIntoFuture', this.hoursIntoFuture, 'this.animatedPrecipSliderValue', this.animatedPrecipSliderValue);
                    },1000);
                } else if(this.animatedPrecipOverlayAction === 'pause') {
					mixpanel.track('map_overlay_pause_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin })
				
                    // this.stopAnimatedPrecipOverlay();
                    clearInterval(this.animatedPrecipTimer);
                    // console.log('stop interval', 'this.hoursIntoFuture', this.hoursIntoFuture, 'this.animatedPrecipSliderValue', this.animatedPrecipSliderValue);
                    this.animatedPrecipOverlayAction = 'play';
                }
            },
            stopAnimatedPrecipOverlay() {
                this.map.overlayMapTypes.clear();
                clearInterval(this.animatedPrecipTimer);
                this.animatedPrecipOverlayAction = 'play';
            },
            toggleShowGroup(group, e) {
                Vue.set(group, 'showGroup', !group.showGroup);

                e.stopPropagation();
                e.preventDefault();

                this.$forceUpdate();

                setTimeout(() => {
                    for (let i = 0; i < this.$refs.acc.length; i++) {
                        if (this.$refs.acc[i].groupID === group.GroupID) {
                            if (group.showGroup) {
                                this.$refs.acc[i].expandItem(true, 0);
                            } else {
                                this.$refs.acc[i].expandItem(false, 0);
                            }
                        }
                    }
                }, 100);

                this.updatePreferences(group);
            },
            updatePreferences(group) {
                if(app.signedInUser.Preferences) {
                    let preference = app.signedInUser.Preferences.SensorMapViewDisplay.filter(p => p.Group.ID === group.GroupID)[0];
                    if(preference) {
                        preference.Group.DisplayAsDefault = group.showGroup;
                    } else {
                        preference = {
                            Group: {
                                ID: group.GroupID,
                                Name: group.GroupName,
                                DisplayAsDefault: group.showGroup,
                            },
                            Zones: []
                        };

                        app.signedInUser.Preferences.SensorMapViewDisplay.push(preference);
                    }
                } else {
                    app.signedInUser.Preferences = {
                        SensorMapViewDisplay: [
                            {
                                Group: {
                                    ID: group.GroupID,
                                    Name: group.GroupName,
                                    DisplayAsDefault: group.showGroup,
                                },
                                Zones: []
                            }
                        ]
                    };
                }

                // Saving in case this feature id needed later - JH
                // this.markers.forEach(m => {
                //     if(m.groupID === group.GroupID) {
                //         if(!group.showGroup) {
                //             m.setMap(null);
                //         } else {
                //             m.setMap(this.map);
                //         }
                //     }
                // });

                DM.http({
                    method: 'POST',
                    url: '/User/UpdatePreferences',
                    data: app.signedInUser.Preferences
                }, true);
            }
        },
        watch: {
            animatedPrecipSliderValue: function(newVal, oldVal) {
                if(this.animatedPrecipOverlayAction === 'play') {
                    // newVal = oldVal;
                    // Hide all the layers
                    for(let i = 0; i < this.map.overlayMapTypes.length; i++) {
                        this.map.overlayMapTypes.getAt(i).setOpacity(0);
                    }

                    this.map.overlayMapTypes.getAt(newVal-1).setOpacity(0.5);

                    this.hoursIntoFuture = newVal;
                    this.updateAnimatedPrecipTimestampDisplay();
                }
            }
        },
        computed: {
            limits() {
                if(this.map && this.map.overlayMapTypes && this.mapLayerType === 'precip') {
                    return {
                        enabled: true,
                        minEnd: this.map.overlayMapTypes.length
                    };
                }

                return {
                    enabled: true,
                    minEnd: 1
                };
            }
        },
        created() {
            if(!app.signedInUser.IsSuperAdmin) {
               this.getLocations(false);
            }

            this.createRefreshTimer();
            this.createScrollTimer();

            eventBus.$on('panMapToLocation', (sensor) => {
                this.map.setCenter(new google.maps.LatLng(sensor.Latitude, sensor.Longitude), 13);
            });

            eventBus.$on('updateMapByZonePreferences', (groupID, zone) => {
                // Saving in case this feature id needed later - JH
                // this.markers.forEach(m => {
                //     if(m.groupID === groupID && m.zoneName === zone.ZoneName) {
                //         if(!zone.showZone) {
                //             m.setMap(null);
                //         } else {
                //             m.setMap(this.map);
                //         }
                //     }
                // });
            });
        },
        mounted() {
            let initMap = () => {
                this.map = new google.maps.Map(document.getElementById("app-dashboard-map"), {
                    center: {lat: 41.675030, lng: -86.251961},
                    zoom: this.zoomLevel,
                    minZoom: 3,
                    mapTypeId: "roadmap",
                    panControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    clickableIcons: false,
                    // scaleControl: true,
                    // disableDefaultUI: true
                });

                this.map.addListener('zoom_changed', (val) => {
                    this.zoomLevel = this.map.getZoom();
                });

                this.drawOverlay();

                eventBus.$emit('mapIsInitialized');
            }

            if (!window.google) {
                window.mapScript = document.createElement('script');
                window.mapScript.src = 'https://maps.google.com/maps/api/js?v=3.44&key=' + app.googleMapsApiKey + '&amp;libraries=places,geometry,drawing';
                document.body.appendChild(window.mapScript);
                window.mapScript.addEventListener('load', () => {
                    initMap();
                });
            } else {
                initMap();
            }
        },
        beforeDestroy() {
            clearInterval(this.refreshTimer);
            clearInterval(this.animatedPrecipTimer);
            clearInterval(this.scrollTimer);
        }
    }

    Vue.directive('scroll', (el, binding) => {
        let f = (evt) => {
            if (binding.value(evt, el)) {
                el.removeEventListener('scroll', f);
            }
        }

        el.addEventListener('scroll', f);
    });
</script>
