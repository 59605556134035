<template src="./settingsPage.html"></template>

<script>
    import Vue from 'vue'
    import usersPage from '../usersPage/usersPage'
    import groupsPage from '../groupsPage/groupsPage'
    import locationsPage from '../locationsPage/locationsPage'

    export default {
        name: 'settings-page',
        components: {
            usersPage,
            groupsPage,
            locationsPage
        },
        data: function () {
            return {
                templateName: '',
                searchText: '',
                includeInactive: false,
                signedInUserIsSuperAdmin: false
            }
        },
        methods: {
            mnuGroupsOnClick() {
                this.templateName = 'groups-page';
            },
            mnuLocationsOnClick() {
                this.templateName = 'locations-page';
            },
            mnuUsersOnClick() {
                this.templateName = 'users-page';
            },
            addUserOnClick() {
                eventBus.$emit('openAddUser');
            },
            addGroupOnClick() {
                eventBus.$emit('openAddGroup');
            },
            addLocationOnClick() {
                eventBus.$emit('openAddLocation');
            },
            searchOnClick() {
                eventBus.$emit('searchItems', {});
            }
        },
        watch: {
            templateName: function(newVal) {
                this.searchText = '';
                this.includeInactive = false;
            }
        },
        computed: {
            searchParams() {
                return {
                    search: this.searchText,
                    includeInactive: this.includeInactive
                };
            }
        },
        created() {
            this.templateName = 'groups-page';
            //  this.templateName = 'locations-page';

            this.signedInUserIsSuperAdmin = app.signedInUser.IsSuperAdmin;
        },
        mounted() {

        }
    }
</script>
