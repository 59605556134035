<template src="./usersPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'users-page',
        props: [
            'searchParams'
        ],
        data: function () {
            return {
                grdUsersFields: [],
                grdUsersItems: [],
                grdUsersSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdUsersClass: '',
                searchUserType: '',
                userTypeOptions: [],
                searchCounty: '',
                countyOptions: [],
                searchOrganization: '',
                organizationOptions: [],
                grdUsersActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'Resend Invite'
                    }
                ],
                selectedRow: null,
            }
        },
        methods: {
            getUsers() {
                let params = {};
                if(this.searchParams) {
                    params = this.searchParams;
                }

                DM.http({
                    method: 'GET',
                    url: '/User/GetAll',
                    params: params
                }).then(response => {
                    response.forEach(u => {
                        u.Name = u.LastName + ', ' + u.FirstName;
                    });
                    this.users = response;
                    this.grdUsersItems = response;
                });
            },
            grdUsersRowOnClick(args) {
                this.selectedRow = args;
            },
            addUserOnClick() {
                eventBus.$emit('showAppModal', this, 'usersEditModal', {
                    mode: "new",
                    user: {},
                    userTypeOptions: this.userTypeOptions,
                    callback: this.getUsers
                });
            },
            grdUsersActionOnClick(args) {
                if(args.item.text === 'Edit') {
                    DM.http({
                        method: 'GET',
                        url: '/User/GetByID',
                        params: {
                            id: this.selectedRow.rowData.ID,
                        }
                    }).then(response => {
                        eventBus.$emit('showAppModal', this, 'usersEditModal', {
                            mode: "edit",
                            user: response,
                            userTypeOptions: this.userTypeOptions,
                            callback: this.getUsers
                        });
                    });
                }

                // if(args.item.text === 'Delete') {
                //     DM.confirmShow('Are you sure you want to delete this user?', '', () => {
                //         this.deleteUser(this.selectedRow.rowData.ID);
                //     }, null, null, 'No');
                // }

                if(args.item.text === 'Resend Invite') {
                    this.resendInvite();
                }
            },
            resendInvite() {
				mixpanel.track('resend_invite', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'invite_user_id': this.selectedRow.rowData.ID })
			
                DM.http({
                    method: "GET",
                    url: "/User/ForgotPassword",
                    params: {
                        email: this.selectedRow.rowData.Email,
                    }
                }).then(response => {
                    DM.alertShow('Invite email sent.');
                });
            },
            deleteUser(userId) {
                DM.http({
                    method: 'POST',
                    url: '/User/Delete',
                    params: {
                        id: userId,
                    }
                }).then(response => {
                    this.getUsers();
                });
            },
            searchUsersOnClick() {
                this.getUsers();
            },
            defineColumns() {
                let self = this;

                if(window.innerWidth < 666) {
                    this.grdUsersFields = [
                        {
                            type: 'template',
                            width: '51px',
                            template: () => {
                                return {
                                    template: Vue.component('columnTemplate', {
                                        template: `<ejs-dropdownbutton
                                                        :items="grdUsersActions"
                                                        :select="select"
                                                        iconCss="e-icons e-ellipsis-vert"
                                                        cssClass= "e-caret-hide">
                                                   </ejs-dropdownbutton>`,
                                        data: function() {
                                            return {
                                                grdUsersActions: self.grdUsersActions
                                            }
                                        },
                                        methods: {
                                            select(args) {
                                                self.grdUsersActionOnClick(args);
                                            }
                                        }
                                    })
                                }
                            }
                        },
                        {
                            key: "Name",
                            label: "Name"
                        },
                        {
                            key: "IsActive",
                            label: "Active",
                            formatter: (field, data) => {
                                return data.IsActive ? 'Y' : 'N';
                            },
                            width: '70px',
                            textAlign: 'Center',
                        },
                    ];
                } else {
                    this.grdUsersFields = [
                        {
                            type: 'template',
                            width: '51px',
                            template: () => {
                                return {
                                    template: Vue.component('columnTemplate', {
                                        template: `<ejs-dropdownbutton
                                                        :items="grdUsersActions"
                                                        :select="select"
                                                        iconCss="e-icons e-ellipsis-vert"
                                                        cssClass= "e-caret-hide">
                                                   </ejs-dropdownbutton>`,
                                        data: function() {
                                            return {
                                                grdUsersActions: self.grdUsersActions
                                            }
                                        },
                                        methods: {
                                            select(args) {
                                                self.grdUsersActionOnClick(args);
                                            }
                                        }
                                    })
                                }
                            }
                        },
                        {
                            key: "LastName",
                            label: "Last Name",
                            width: '130px',
                        },
                        {
                            key: "FirstName",
                            label: "First Name",
                            width: '120px',
                        },
                        {
                            key: "PhoneNumber",
                            label: "Phone",
                            width: '120px',
                        },
                        {
                            key: "Email",
                            label: "Email",
                        },
                        {
                            key: "Access",
                            label: "Access",
                            formatter: (field, data) => {
                                let access = [];
                                if(data.IsSuperAdmin) {
                                    access.push('Super Admin');
                                }

                                if(data.Groups) {
                                    data.Groups.forEach(g => {
                                        access.push(g.Name + ' ' + app.camelPad(g.Permission));
                                    });
                                }

                                return access.join(' | ');
                            },
                        },
                        {
                            key: "IsActive",
                            label: "Active",
                            formatter: (field, data) => {
                                return data.IsActive ? 'Y' : 'N';
                            },
                            width: '70px',
                            textAlign: 'Center',
                        },
                    ];
                }
            },
        },
        created() {
            this.getUsers();

            this.defineColumns();

            eventBus.$on('openAddUser', this.addUserOnClick);
            eventBus.$on('searchItems', this.getUsers);
        },
        mounted() {
            let defineColumns;
            window.onresize = () => {
              clearTimeout(defineColumns);
              defineColumns = setTimeout(this.defineColumns, 100);
            };
        },
        beforeDestroy() {
            eventBus.$off('openAddUser', this.addUserOnClick);
            eventBus.$off('searchItems', this.getUsers);
        }
    }
</script>
