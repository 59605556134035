<template src="./locationsPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'locations-page',
        props: [
            'searchParams'
        ],
        data: function () {
            return {
                grdLocationsFields: [],
                grdLocationsItems: [],
                grdLocationsSortBy: {
                    columns: [
                        {field: 'Name', direction: 'Ascending'},
                    ]
                },
                grdLocationsClass: 'dm-bg-color-black',
                searchLocationType: '',
                locationTypeOptions: [],
                searchCounty: '',
                countyOptions: [],
                searchOrganization: '',
                organizationOptions: [],
                searchText: '',
                grdLocationsActions: [
                    {
                        text: 'Edit'
                    },
                    {
                        text: 'File Attachments'
                    },
                    // {
                    //     text: 'Delete'
                    // }
                ],
                selectedRow: null,
                includeInactive: false
            }
        },
        methods: {
            getLocations() {
                let params = {};
                if(this.searchParams) {
                    params = this.searchParams;
                }

                DM.http({
                    method: 'GET',
                    url: '/Location/GetAll',
                    params: params
                }).then(response => {
                    this.locations = response;
                    this.grdLocationsItems = response;
                });
            },
            grdLocationsRowOnClick(args) {
                this.selectedRow = args;
            },
            addLocationOnClick() {
                eventBus.$emit('showAppModal', this, 'locationsEditModal', {
                    mode: "new",
                    location: {},
                    locationTypeOptions: this.locationTypeOptions,
                    callback: this.getLocations
                });
            },
            grdLocationsActionOnClick(args) {
                if(args.item.text === 'Edit') {
                    DM.http({
                        method: 'GET',
                        url: '/Location/GetByID',
                        params: {
                            id: this.selectedRow.rowData.ID,
                        }
                    }).then(response => {
                        delete response.SensorReadings;

                        eventBus.$emit('showAppModal', this, 'locationsEditModal', {
                            mode: "edit",
                            location: response,
                            callback: this.getLocations
                        });
                    });
                }

                if(args.item.text === 'File Attachments') {
					mixpanel.track('location_attachment_click', {'distinct_id': app.userID, 'user_id': app.userID, 'isSuperAdmin': app.signedInUser.IsSuperAdmin, 'edit_location_id': this.selectedRow.rowData.ID })
				
                    DM.http({
                        method: 'GET',
                        url: '/Location/GetAttachmentsByLocationID',
                        params: {
                            locationID: this.selectedRow.rowData.ID,
                        }
                    }).then(response => {
                        eventBus.$emit('showAppModal', this, 'fileAttachmentsModal', {
                            mode: "edit",
                            location: this.selectedRow.rowData,
                            files: response, //[{FileName: 'file.png', Url: '/attachments/file.png'}],
                            callback: () => {}
                        });
                    });
                }

                if(args.item.text === 'Delete') {
                    // DM.confirmShow('Are you sure you want to delete this location?', '', () => {
                    //     this.deleteLocation(this.selectedRow.rowData.LocationID, this.selectedRow.rowData.LocationRowVersion);
                    // }, null, null, 'No');
                }
            },
            deleteLocation(locationId) {
                DM.http({
                    method: 'POST',
                    url: '/Sensors/Delete',
                    data: {
                        LocationID: locationId,
                    }
                }).then(response => {
                    this.getLocations();
                });
            },
            defineColumns() {
                let self = this;

                if(window.innerWidth < 666) {
                    this.grdLocationsFields = [
                        {
                            type: 'template',
                            width: '51px',
                            template: () => {
                                return {
                                    template: Vue.component('columnTemplate', {
                                        template: `<ejs-dropdownbutton
                                                        :items="grdLocationsActions"
                                                        :select="select"
                                                        iconCss="e-icons e-ellipsis-vert"
                                                        cssClass= "e-caret-hide">
                                                   </ejs-dropdownbutton>`,
                                        data: function() {
                                            return {
                                                grdLocationsActions: self.grdLocationsActions
                                            }
                                        },
                                        methods: {
                                            select(args) {
                                                self.grdLocationsActionOnClick(args);
                                            }
                                        }
                                    })
                                }
                            }
                        },
                        {
                            key: 'Name',
                            label: 'Name'
                        },
                        {
                            key: "IsActive",
                            label: "Active",
                            formatter: (field, data) => {
                                return data.IsActive ? 'Y' : 'N';
                            },
                            width: '70px',
                            textAlign: 'Center',
                        },
                    ];
                } else {
                    this.grdLocationsFields = [
                        {
                            type: 'template',
                            width: '51px',
                            template: () => {
                                return {
                                    template: Vue.component('columnTemplate', {
                                        template: `<ejs-dropdownbutton
                                                        :items="grdLocationsActions"
                                                        :select="select"
                                                        iconCss="e-icons e-ellipsis-vert"
                                                        cssClass= "e-caret-hide">
                                                   </ejs-dropdownbutton>`,
                                        data: function() {
                                            return {
                                                grdLocationsActions: self.grdLocationsActions
                                            }
                                        },
                                        methods: {
                                            select(args) {
                                                self.grdLocationsActionOnClick(args);
                                            }
                                        }
                                    })
                                }
                            }
                        },
                        {
                            key: 'Name',
                            label: 'Name'
                        },
                        {
                            key: 'GroupName',
                            label: 'Group'
                        },
                        {
                            key: 'SensorSerialNumber',
                            label: 'Sensor Serial Number'
                        },
                        {
                            key: 'FiPySerialNumber',
                            label: 'FiPy Serial Number'
                        },
                        {
                            key: 'Type',
                            label: 'Type',
                            type: 'custom',
                            formatter(field, data) {
                                return data.Type.Key;
                            }
                        },
                        {
                            key: "IsActive",
                            label: "Active",
                            formatter: (field, data) => {
                                return data.IsActive ? 'Y' : 'N';
                            },
                            width: '70px',
                            textAlign: 'Center',
                        },
                    ];
                }
            },
        },
        created() {
            this.getLocations();

            this.defineColumns();

            eventBus.$on('openAddLocation', this.addLocationOnClick);

            eventBus.$on('searchItems', this.getLocations);
        },
        mounted() {
            let defineColumns;
            window.onresize = () => {
              clearTimeout(defineColumns);
              defineColumns = setTimeout(this.defineColumns, 100);
            };
        },
        beforeDestroy() {
            eventBus.$off('openAddLocation', this.addLocationOnClick);
            eventBus.$off('searchItems', this.getLocations);
        }
    }
</script>
