export default {
    props: [
        'modalRefGuid',
    ],
    data() {
        return {
            ref: null,
            sfTarget: document.getElementById('app'),
            modalRef: null
        }
    },
    methods: {
        destroy() {
            this.$destroy();
        }
    },
    watch: {
        modalRef: function() {
            this.modalRef.show();

            this.modalRef.$on("close", () => {
                eventBus.$emit('closeAppModal', {
                    modalRefGuid: this.modalRefGuid,
                    modalName: this.modalName
                });
            });
        },
    },
    created() {
        // console.log(this.modalRefGuid);
    },
    mounted() {
        try {
            this.savedInitialData = {};
            for(let property in this.$data) {
                if(!(this.$data[property] && typeof this.$data[property] === 'object' && this.$data[property].hasOwnProperty('innerHTML'))) {
                    this.savedInitialData[property] = this.$data[property];
                }
            }
        } catch(error) {
            console.log(error);
        }

        this.modalRef = this.$refs[this.modalName];
        // console.log(this.modalRef);
    }
}